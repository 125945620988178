import React, { useState } from 'react';
import MainTable from 'common/table/index';
import { Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Actions from 'common/table/actions';
import { useDispatch } from 'react-redux';
import { openAddCategoryStores } from 'services/apis/models';
import { useUpdateCategoryMutation } from 'services/apis/categoryApi';
import { useRemoveStoreMutation, useUpdateStoreMutation } from 'services/apis/storesApi';

const CategoryStores = ({ stores, setFilterObject, storesCount }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [updateStore] = useUpdateStoreMutation();
    const [removeStore, removeStorResults] = useRemoveStoreMutation();

    const actionsList = (item) => [
        {
            type: 'active',
            func: () => {
                updateStore({
                    id: item?.id,
                    body: { store: { is_visible: !item?.is_visible } },
                });
            },
        },
        {
            type: 'edit',
            func: () => {
                navigate('/stores/update-store/1');
            },
        },
        {
            type: 'delete',
            func: () => {
                removeStore(item?.id);
            },
        },
    ];
    const createBtnFun = () => {
        dispatch(openAddCategoryStores());
    };
    const [checked, setChecked] = useState([false]);
    const [perPage, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);

    const list = stores;
    const mappedList = list?.map((item) => {
        return {
            ...item,
            action: <Actions list={actionsList(item)} type="not-menu" item={item} />,
        };
    });

    return (
        <Flex gap={6} width="100%" flexWrap="wrap" flexDirection="column" bg="white" p="20px" borderRadius="10px">
            <MainTable
                title="Stores"
                Data={mappedList || [{}]}
                Columns={[
                    {
                        Header: 'Image',
                        accessor: 'image',
                    },
                    {
                        Header: 'Name',
                        accessor: 'name',
                    },

                    {
                        Header: 'Coupons',
                        accessor: 'promocodes_count',
                    },
                    {
                        Header: 'Products',
                        accessor: 'products_count',
                    },
                    {
                        Header: 'created dates',
                        accessor: 'created_at',
                    },
                    {
                        Header: 'Action',
                        accessor: 'action',
                    },
                ]}
                isChecked
                isSearch={true}
                isFilter={false}
                searchFun={(keyword) =>
                    setFilterObject((prevState) => {
                        return {
                            ...prevState,
                            keyword: keyword,
                        };
                    })
                }
                selectedData={() => {}}
                currentpage={1}
                setPage={() => {}}
                perPage={perPage}
                totalPage={1}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                checked={checked}
                setChecked={setChecked}
                setPerPage={setPerPage}
                createBtnTitle="Add a New Store"
                createBtnFun={createBtnFun}
                placeholder="Search Stores..."
                filterList={<></>}
                pagesNumber={storesCount == 0 ? 1 : storesCount}
            />
        </Flex>
    );
};

export default CategoryStores;
