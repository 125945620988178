import { Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFetchCountriesQuery } from 'services/apis/countriesApi';
import CountriesTemplate from 'templates/countries';

const Countries = ({ setTitle }) => {
    const [filterObject, setFilterObject] = useState({
        page_number: 1,
        keyword: '',
    });

    const { data, error, isLoading } = useFetchCountriesQuery(filterObject);
    const list = data?.data?.countries;
    const count = Math.ceil(data?.extra?.total_count / 10);

    useEffect(() => {
        setTitle('Countries');
    }, []);
    return isLoading ? <Spinner /> : <CountriesTemplate count={count} list={list} setFilterObject={setFilterObject} />;
};

export default Countries;
