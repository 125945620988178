import React, { useEffect, useState } from 'react';
import MainTable from 'common/table/index';
import Actions from 'common/table/actions';
import { Box, Image, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRemoveCurrencyMutation } from 'services/apis/currenciesApi';
import { BsImageFill } from 'react-icons/bs';
import { toast } from 'react-toastify';

const CurrenciesTemplate = ({ list, setFilterObject, count }) => {
    const navigate = useNavigate();
    const [removeCurrency] = useRemoveCurrencyMutation();
    const actionsList = (item) => [
        {
            type: 'edit',
            func: () => {
                navigate(`/currencies/update-currency/${item?.id}`);
            },
        },
        {
            type: 'delete',
            func: () => {
                removeCurrency(item?.id)
                    .then((res) => {
                        //console.log(res);
                        if (res?.error) toast.error("you can't delete this currency as it's used in some products ");
                    })
                    .catch(() => {});
            },
        },
    ];
    const [checked, setChecked] = useState([false]);
    const [perPage, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        setFilterObject((prevState) => {
            return {
                ...prevState,
                keyword: keyword,
                page_number: pageNumber,
                page_size: pageNumber == 0 ? 200 : 10,
            };
        });
    }, [pageNumber, keyword]);

    const mappedList = list?.map((item) => {
        return {
            ...item,
            image: item?.image ? (
                <Image src={item?.image} width="35px" height="25px" />
            ) : (
                <BsImageFill width="35px" height="25px" />
            ),
            action: <Actions list={actionsList(item)} item={item} />,
        };
    });
    const createBtnFun = (val) => {
        navigate('/currencies/add-new-currency');
    };

    return (
        <Box px={6} w="100%" pt={4}>
            <MainTable
                Data={mappedList || [{}]}
                Columns={[
                    {
                        Header: 'Name',
                        accessor: 'name',
                    },
                    {
                        Header: 'Code',
                        accessor: 'iso_code',
                    },

                    {
                        Header: 'Action',
                        accessor: 'action',
                    },
                ]}
                isChecked
                isSearch={false}
                isFilter={false}
                searchFun={(val) => {
                    setPageNumber(1);
                    setKeyword(val);
                }}
                selectedData={() => {}}
                currentpage={1}
                setPage={() => {}}
                perPage={perPage}
                totalPage={1}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                checked={checked}
                setChecked={setChecked}
                setPerPage={setPerPage}
                createBtnTitle="Add a New Currency"
                createBtnFun={createBtnFun}
                placeholder="Search Currencies..."
                filterList={<></>}
                pagesNumber={count == 0 ? 1 : count}
            />
        </Box>
    );
};

export default CurrenciesTemplate;
