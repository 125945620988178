import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-headers';

const couponsApi = createApi({
    reducerPath: 'coupons',
    baseQuery: baseQuery,
    endpoints(builder) {
        return {
            removeCoupon: builder.mutation({
                query: (id) => {
                    return {
                        url: `/promocodes/${id}`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: ['Coupon'],
            }),
            addCoupon: builder.mutation({
                query: (body) => {
                    return {
                        url: '/promocodes',
                        method: 'POST',
                        body: body,
                    };
                },
                invalidatesTags: ['Coupon'],
            }),
            updateCoupon: builder.mutation({
                query: (data) => {
                    return {
                        url: `/promocodes/${data?.id}`,
                        method: 'PUT',
                        body: data?.body,
                    };
                },
                invalidatesTags: ['Coupon'],
            }),
            fetchCoupons: builder.query({
                query: (filterObj) => {
                    return {
                        url: '/promocodes',
                        method: 'GET',
                        params: filterObj,
                    };
                },
                providesTags: ['Coupon'],
            }),
            fetchCoupon: builder.query({
                query: (id) => `/promocodes/${id}`,
                providesTags: (result, error, id) => [{ type: 'Coupon', id }],
            }),
        };
    },
});

export const {
    useFetchCouponsQuery,
    useAddCouponMutation,
    useUpdateCouponMutation,
    useFetchCouponQuery,
    useRemoveCouponMutation,
} = couponsApi;
export { couponsApi };
