import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-headers';

const categoriesApi = createApi({
    reducerPath: 'categories',
    baseQuery: baseQuery,
    endpoints(builder) {
        return {
            removeCategory: builder.mutation({
                query: (id) => {
                    return {
                        url: `/lookups/categories/${id}`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: ['Category'],
            }),
            addCategory: builder.mutation({
                query: (body) => {
                    return {
                        url: '/lookups/categories',
                        method: 'POST',
                        body: body,
                    };
                },
                invalidatesTags: ['Category'],
            }),
            updateCategory: builder.mutation({
                query: (data) => {
                    return {
                        url: `/lookups/categories/${data?.id}`,
                        method: 'PUT',
                        body: data?.body,
                    };
                },
                invalidatesTags: ['Category'],
            }),
            fetchCtegories: builder.query({
                query: (filterObj) => {
                    return {
                        url: '/lookups/categories',
                        method: 'GET',
                        params: filterObj,
                    };
                },
                providesTags: ['Category'],
            }),
            fetchCategory: builder.query({
                query: (id) => `/lookups/categories/${id}`,
                providesTags: (result, error, id) => [{ type: 'Category', id }],
            }),
        };
    },
});

export const {
    useFetchCtegoriesQuery,
    useAddCategoryMutation,
    useUpdateCategoryMutation,
    useFetchCategoryQuery,
    useRemoveCategoryMutation,
} = categoriesApi;
export { categoriesApi };
