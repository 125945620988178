import React, { useEffect, useState } from 'react';
import MainTable from 'common/table/index';
import Actions from 'common/table/actions';
import { Box, Button, Divider, HStack, Image, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useRemoveAdminUserMutation } from 'services/apis/users';
import { BsImageFill } from 'react-icons/bs';

const AdminsTemplate = ({ list, setFilterObject, count, user }) => {
    const navigate = useNavigate();
    const [removeAdminUser] = useRemoveAdminUserMutation();

    const actionsList = (item) => [
        {
            type: 'edit',
            func: () => navigate(`/admins/update-admin/${item?.id}/${item?.privilege}`),
        },
        {
            type: 'delete',
            func: () => {
                removeAdminUser(item?.id);
            },
        },
    ];
    const [checked, setChecked] = useState([false]);
    const [perPage, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [type, setType] = useState(null);

    useEffect(() => {
        setFilterObject((prevState) => {
            return {
                ...prevState,
                page_number: pageNumber,
                page_size: pageNumber == 0 ? 200 : 10,
            };
        });
    }, [pageNumber]);
    const mappedList = list?.map((item) => {
        return {
            ...item,
            image: item?.avatar ? (
                <Image src={item?.avatar} width="35px" height="25px" />
            ) : (
                <BsImageFill width="35px" height="25px" />
            ),

            date: <Text color="main_font">{dayjs(item?.created_at).format('DD/MM/YYYY')}</Text>,
            action: <Actions list={actionsList(item)} item={item} />,
        };
    });
    const createBtnFun = (val) => {
        navigate('/admins/add-new-admin');
    };
    const filterList = () => {
        return (
            <>
                <RadioGroup value={type} onChange={(e) => setType(e)} p={2}>
                    <Text fontSize="14px" fontWeight="600">
                        Type
                    </Text>
                    <Divider my={4} />
                    <Stack gap={4}>
                        <Radio value="0">Admin</Radio>
                        <Radio value="1">Super Admin</Radio>
                        <Radio value="2">Editor</Radio>
                    </Stack>
                    <HStack justifyContent="end">
                        <Button
                            color="#BABCBC"
                            onClick={() => {
                                setType('-1');
                                setPageNumber(1);
                                setFilterObject((prevState) => {
                                    return {
                                        page_number: 1,
                                    };
                                });
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            color="main_color"
                            onClick={() => {
                                setPageNumber(1);
                                setFilterObject((prevState) => {
                                    return {
                                        ...prevState,
                                        privilege: type,
                                        page_number: 1,
                                    };
                                });
                            }}
                        >
                            Apply
                        </Button>
                    </HStack>
                </RadioGroup>
            </>
        );
    };
    return (
        <Box px={6} w="100%" pt={4}>
            <MainTable
                Data={mappedList || [{}]}
                Columns={[
                    {
                        Header: 'ID',
                        accessor: 'id',
                    },
                    {
                        Header: 'Image',
                        accessor: 'image',
                    },
                    {
                        Header: 'Name',
                        accessor: 'name',
                    },
                    {
                        Header: 'Type',
                        accessor: 'privilege',
                    },
                    {
                        Header: 'Email',
                        accessor: 'email',
                    },
                    {
                        Header: 'Phone number',
                        accessor: 'phone_number',
                    },
                    {
                        Header: 'created dates',
                        accessor: 'date',
                    },
                    {
                        Header: 'Action',
                        accessor: 'action',
                    },
                ]}
                isChecked
                isSearch
                isFilter={user?.privilege == 'admin' ? false : true}
                searchFun={(val) => {
                    setFilterObject((s) => {
                        return { ...s, keyword: val };
                    });
                }}
                selectedData={() => {}}
                currentpage={1}
                setPage={() => {}}
                perPage={perPage}
                totalPage={1}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                checked={checked}
                setChecked={setChecked}
                setPerPage={setPerPage}
                createBtnTitle="Add a New Admin"
                createBtnFun={createBtnFun}
                placeholder="Search Admins..."
                filterList={filterList()}
                pagesNumber={count == 0 ? 1 : count}
            />
        </Box>
    );
};

export default AdminsTemplate;
