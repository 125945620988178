import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Flex, Box, VStack } from '@chakra-ui/react';
import Sidenav from './components/sidenav';
import Header from './components/header';
import { useFetchUserQuery } from 'services/apis/users';

const Layout = ({ title, user }) => {
    const token = localStorage.getItem('token');
    const editorAuthRoutes = ['/coupons'];
    const { pathname } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (!token) navigate('/auth/login');
    }, []);

    useEffect(() => {
        if (user?.privilege == 'editor' && !editorAuthRoutes.includes(pathname)) navigate('/', { replace: true });
    }, [user]);
    return (
        <Flex>
            <Box width={{ base: '10%', md: '20%' }}>
                <Sidenav user={user} />
            </Box>
            <VStack width={{ base: '90%', md: '80%' }} bg="#cecece61">
                <Header title={title} user={user} />
                <Outlet />
            </VStack>
        </Flex>
    );
};

export default Layout;
