import { HStack, Spinner, Stack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink, useParams } from 'react-router-dom';
import { useFetchCountryQuery } from 'services/apis/countriesApi';
import { useFetchProductQuery } from 'services/apis/productsApi';
import CreateCountryTemplate from 'templates/countries/update';
import UpdateProductTemplate from 'templates/products/update';

const UpdateCountry = ({ setTitle }) => {
    const { id } = useParams();
    const { data, error, isLoading } = useFetchCountryQuery(id);
    useEffect(() => {
        const title = (
            <HStack>
                <NavLink to="/countries">Countries</NavLink>
                <IoIosArrowForward />
                <Text>Edit </Text>
            </HStack>
        );
        setTitle(title);
    }, []);
    return isLoading ? <Spinner /> : <CreateCountryTemplate data={data?.data?.country} />;
};

export default UpdateCountry;
