import React from 'react';
import FormIndex from 'common/forms';
import { useFormik } from 'formik';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAddCategoryMutation } from 'services/apis/categoryApi';
import { structure } from 'components/organisms/categories/forms/add-form';
import { toast } from 'react-toastify';
const CreateCategoryTemplate = ({ storesList }) => {
    const [addCategory, { isLoading }] = useAddCategoryMutation();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: { is_visible: true },

        onSubmit: (values) => {
            const mappedData = values?.store_ids?.map((data) => data?.value);
            addCategory({ category: { ...values, store_ids: mappedData } }).then((res) => {
                if (res?.error) toast.error(res?.error?.data?.message);
                else navigate('/categories');
            });
        },
    });

    return (
        <FormIndex formStructures={structure(storesList)} formik={formik}>
            <Button
                isLoading={isLoading}
                type="submit"
                variant="solid"
                width={{ base: '100%', md: '25%' }}
                alignSelf="self-start"
            >
                Save
            </Button>
        </FormIndex>
    );
};

export default CreateCategoryTemplate;
