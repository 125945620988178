import { Flex } from '@chakra-ui/react';
import CEditableInput from 'common/forms/components/CEditableInput';
import React from 'react';
import { useAddCurrencyMutation, useUpdateCurrencyMutation } from 'services/apis/currenciesApi';

const UpdateForm = ({ data }) => {
    const [updateCurrency] = useUpdateCurrencyMutation();

    const handelUpdate = (val, name) => {
        const body = {
            currency: { ...data, [`${name}`]: val },
        };

        updateCurrency({ id: data?.id, body });
    };

    return (
        <Flex
            gap={6}
            width="100%"
            flexWrap="wrap"
            flexDirection="column"
            bg="white"
            p="20px"
            borderRadius="10px"
            mt={6}
        >
            <CEditableInput defaultValue={data?.iso_code} name="iso_code" handelSubmit={handelUpdate} label="Code" />
            <CEditableInput
                defaultValue={data?.name_en}
                name="name_en"
                handelSubmit={handelUpdate}
                label="Currency name in English"
            />
            <CEditableInput
                defaultValue={data?.name_ar}
                name="name_ar"
                handelSubmit={handelUpdate}
                label="currency name in Arabic"
            />
        </Flex>
    );
};

export default UpdateForm;
