import React from 'react';
import { VStack } from '@chakra-ui/react';
import UpdateForm from 'components/organisms/stores/forms/update-form';
import StoreProductsData from 'components/organisms/stores/components/store-products-data';
import StoreProducts from 'components/organisms/stores/components/store-products';
import StoreCoupons from 'components/organisms/stores/components/store-coupons';
import AddCoupons from 'components/organisms/stores/models/add-coupons';
const UpdateStoreTemplate = ({
    data,
    categoriesList,
    productsList,
    couponsList,
    setProductFilter,
    setCouponFilter,
    productsCount,
    couponsCount,
}) => {
    return (
        <VStack w={{ base: '100%', md: '90%' }} gap={6} mb={6}>
            <UpdateForm data={data} categoriesList={categoriesList} />
            <StoreProductsData data={data} />
            <StoreProducts products={productsList} setProductFilter={setProductFilter} productsCount={productsCount} />
            <StoreCoupons coupons={couponsList} setCouponFilter={setCouponFilter} couponsCount={couponsCount} />
            <AddCoupons />
        </VStack>
    );
};

export default UpdateStoreTemplate;
