import React from 'react';
import LayoutPage from 'common/layout/index';
import Admins from 'pages/admins/index';
import Categories from 'pages/categories/index';
import Coupons from 'pages/coupons';
import Notifications from 'pages/notifications';
import Products from 'pages/products';
import Stores from 'pages/stores';
import Users from 'pages/users';
import CreateStore from 'pages/stores/create';
import UpdateStore from 'pages/stores/update';
import CreateProduct from 'pages/products/create';
import UpdateProduct from 'pages/products/update';
import CreateCategory from 'pages/categories/create';
import UpdateCategory from 'pages/categories/update';
import CreateCoupon from 'pages/coupons/create';
import UpdateCoupon from 'pages/coupons/update';
import Profile from 'pages/profile';
import Login from 'pages/login';
import Countries from 'pages/countries';
import CreateCountriesTemplate from 'templates/countries/create';
import CreateCountry from 'pages/countries/create';
import UpdateCountry from 'pages/countries/update';
import Currencies from 'pages/currencies';
import CreateCurrency from 'pages/currencies/create';
import UpdateCurrency from 'pages/currencies/update';
import UserDetails from 'pages/users/details';
import UserForm from 'pages/admins/form';
import Banners from 'pages/banners';
import TabsLayout from 'components/organisms/documents/tabs';
import Contactus from 'pages/documents/contactus';
import PrivacyPolicy from 'pages/documents/privacyPolicy';
import TermaAndConditions from 'pages/documents/termsAndConditions';
import Faq from 'pages/documents/faq';
import CreateNotification from 'pages/notifications/create';
import PixelData from 'pages/documents/pixelData';

// Define types for route objects and the routes function argument

export const routes = ({ title, setTitle, user }) => {
    return [
        {
            path: '/',
            element: <LayoutPage title={title} user={user} />,
            children: [
                {
                    path: '/profile',
                    element: <Profile setTitle={setTitle} user={user} />,
                },

                {
                    path: '/admins',
                    element: <Admins setTitle={setTitle} user={user} />,
                },
                {
                    path: '/admins/add-new-admin',
                    element: <UserForm setTitle={setTitle} user={user} />,
                },
                {
                    path: '/admins/update-admin/:id/:user_type',
                    element: <UserForm setTitle={setTitle} user={user} />,
                },
                {
                    path: '/categories',
                    element: <Categories setTitle={setTitle} />,
                },
                {
                    path: '/categories/add-new-category',
                    element: <CreateCategory setTitle={setTitle} />,
                },
                {
                    path: '/notifications',
                    element: <Notifications setTitle={setTitle} />,
                },
                {
                    path: '/notifications/add-new-notification',
                    element: <CreateNotification setTitle={setTitle} />,
                },

                {
                    path: '/categories/update-category/:id',
                    element: <UpdateCategory setTitle={setTitle} />,
                },
                {
                    path: '/countries',
                    element: <Countries setTitle={setTitle} />,
                },
                {
                    path: '/countries/add-new-country',
                    element: <CreateCountry setTitle={setTitle} />,
                },
                {
                    path: '/countries/update-country/:id',
                    element: <UpdateCountry setTitle={setTitle} />,
                },
                {
                    path: '/currencies',
                    element: <Currencies setTitle={setTitle} />,
                },
                {
                    path: '/currencies/add-new-currency',
                    element: <CreateCurrency setTitle={setTitle} />,
                },

                {
                    path: '/currencies/update-currency/:id',
                    element: <UpdateCurrency setTitle={setTitle} />,
                },
                {
                    path: '/coupons',
                    element: <Coupons setTitle={setTitle} user={user} />,
                },
                {
                    path: '/coupons/add-new-coupon',
                    element: <CreateCoupon setTitle={setTitle} />,
                },
                {
                    path: '/coupons/update-coupon/:id',
                    element: <UpdateCoupon setTitle={setTitle} />,
                },
                {
                    path: '/notifications',
                    element: <Notifications setTitle={setTitle} />,
                },
                {
                    path: '/products',
                    element: <Products setTitle={setTitle} />,
                },
                {
                    path: '/products/add-new-product',
                    element: <CreateProduct setTitle={setTitle} />,
                },
                {
                    path: '/products/update-product/:id',
                    element: <UpdateProduct setTitle={setTitle} />,
                },
                {
                    path: '/banners',
                    element: <Banners setTitle={setTitle} />,
                },
                {
                    path: '/stores',
                    element: <Stores setTitle={setTitle} />,
                },
                {
                    path: '/stores/add-new-store',
                    element: <CreateStore setTitle={setTitle} />,
                },
                {
                    path: '/stores/update-store/:id',
                    element: <UpdateStore setTitle={setTitle} />,
                },

                {
                    path: '/users',
                    element: <Users setTitle={setTitle} />,
                },
                {
                    path: '/users/:id',
                    element: <UserDetails setTitle={setTitle} />,
                },
                {
                    path: '/documents',
                    element: <TabsLayout />,
                    children: [
                        {
                            path: '/documents/pixel',
                            element: <PixelData setTitle={setTitle} user={user} />,
                        },
                        {
                            path: '/documents/contact-us',
                            element: <Contactus setTitle={setTitle} user={user} />,
                        },
                        {
                            path: '/documents/privacy-policy',
                            element: <PrivacyPolicy setTitle={setTitle} user={user} />,
                        },
                        {
                            path: '/documents/terms-conditions',
                            element: <TermaAndConditions setTitle={setTitle} user={user} />,
                        },
                        {
                            path: '/documents/faq',
                            element: <Faq setTitle={setTitle} user={user} />,
                        },
                    ],
                },
            ],
        },
        {
            path: '/auth/login',
            element: <Login />,
        },
    ];
};
