import React, { useEffect } from 'react';
import { useFetchDocumentsQuery } from 'services/apis/documents';
import TermaAndConditionsTmplate from 'templates/documents/termaAndConditions';

const TermaAndConditions = ({ setTitle }) => {
    const { data } = useFetchDocumentsQuery('terms_and_conditions');
    const document = data?.data?.document;
    useEffect(() => {
        setTitle('Terms And Conditions');
    }, []);
    return <TermaAndConditionsTmplate document={document} />;
};

export default TermaAndConditions;
