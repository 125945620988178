import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-headers';

const storesApi = createApi({
    reducerPath: 'stores',
    baseQuery: baseQuery,
    endpoints(builder) {
        return {
            removeStore: builder.mutation({
                query: (id) => {
                    return {
                        url: `/stores/${id}`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: ['Store'],
            }),
            addStore: builder.mutation({
                query: (body) => {
                    return {
                        url: '/stores',
                        method: 'POST',
                        body: body,
                    };
                },
                invalidatesTags: ['Store'],
            }),
            updateStore: builder.mutation({
                query: (data) => {
                    return {
                        url: `/stores/${data?.id}`,
                        method: 'PUT',
                        body: data?.body,
                    };
                },
                invalidatesTags: ['Store'],
            }),
            fetchStores: builder.query({
                query: (filterObj) => {
                    //console.log(filterObj, 'filterObj');
                    return {
                        url: '/stores',
                        method: 'GET',
                        params: filterObj,
                    };
                },
                providesTags: ['Store'],
            }),
            fetchStore: builder.query({
                query: (id) => `/stores/${id}`,
                providesTags: (result, error, id) => [{ type: 'Store', id }],
            }),
        };
    },
});

export const {
    useFetchStoresQuery,
    useAddStoreMutation,
    useUpdateStoreMutation,
    useFetchStoreQuery,
    useRemoveStoreMutation,
} = storesApi;
export { storesApi };
