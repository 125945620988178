import { HStack, Spinner, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink, useParams } from 'react-router-dom';
import { useFetchCouponQuery } from 'services/apis/couponsApi';
import { useFetchStoresQuery } from 'services/apis/storesApi';
import UpdateCouponTemplate from 'templates/coupons/update';

const UpdateCoupon = ({ setTitle }) => {
    const { id } = useParams();
    const { data, error, isLoading } = useFetchCouponQuery(id);
    const { data: stores } = useFetchStoresQuery({ page_size: 200 });
    const storesList = stores?.data?.stores;

    useEffect(() => {
        const title = (
            <HStack>
                <NavLink to="/coupons">Coupons</NavLink>
                <IoIosArrowForward />
                <Text>Edit </Text>
            </HStack>
        );
        setTitle(title);
    }, []);
    return isLoading ? <Spinner /> : <UpdateCouponTemplate data={data?.data?.promocode} storesList={storesList} />;
};

export default UpdateCoupon;
