import { HStack, Spinner, Stack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink, useParams } from 'react-router-dom';
import { useFetchProductQuery } from 'services/apis/productsApi';
import UpdateProductTemplate from 'templates/products/update';

const UpdateProduct = ({ setTitle }) => {
    const { id } = useParams();
    const { data, error, isLoading } = useFetchProductQuery(id);
    useEffect(() => {
        const title = (
            <HStack>
                <NavLink to="/products">Products</NavLink>
                <IoIosArrowForward />
                <Text>Edit </Text>
            </HStack>
        );
        setTitle(title);
    }, []);
    return isLoading ? <Spinner /> : <UpdateProductTemplate data={data?.data?.product} />;
};

export default UpdateProduct;
