import { Box, Button, Flex, Image, VStack } from '@chakra-ui/react';
import CUpload from 'common/forms/components/CUpload';
import React, { useEffect, useState } from 'react';
import { BiImageAdd } from 'react-icons/bi';
import { IoIosClose } from 'react-icons/io';
import { useAddBannerMutation, useFetchBannersQuery, useRemoveBannerMutation } from 'services/apis/banners';

const Banners = ({ setTitle }) => {
    const { data } = useFetchBannersQuery({ page_size: 20 });
    const list = data?.data?.featured_banners;
    const [addBanner] = useAddBannerMutation();

    const [removeBanner] = useRemoveBannerMutation();
    useEffect(() => {
        setTitle('Banners');
    }, []);

    const handeAddImage = (value, index) => {
        const body = {
            featured_banner: {
                url: value,
                is_visible: true,
            },
        };
        addBanner(body);
    };

    const handelDelete = (index, data) => {
        removeBanner(data?.id);
    };

    return (
        <VStack width="90%" bg="white" p="20px" borderRadius="10px">
            <Flex gap={6} width="100%" flexWrap="wrap" flexDirection="row" mt={6}>
                <Box
                    height="100px"
                    position="relative"
                    width={{ base: '95%', md: '30%' }}
                    bg="#E4E4E4"
                    borderRadius="4px"
                >
                    <CUpload
                        changeAction={(value) => handeAddImage(value)}
                        label={'h=369px X w=991px'}
                        type="banner"
                        value={<BiImageAdd />}
                        direction="column"
                        style={{
                            height: 'auto',
                            marginBottom: '-10px',
                        }}
                    />
                </Box>
                {list?.map((data, index) => {
                    return (
                        <Box position="relative" width={{ base: '95%', md: '30%' }} bg="#E4E4E4" borderRadius="4px">
                            <Image
                                key={index}
                                src={list?.[index]?.url}
                                style={{
                                    height: '100%',

                                    width: '100%',
                                }}
                            />
                            <Flex
                                cursor="pointer"
                                position="absolute"
                                top="5px"
                                right="5px"
                                bgColor="white"
                                borderRadius="50%"
                                opacity=".5"
                                width="20px"
                                height="20px"
                                alignItems="center"
                                justifyContent="center"
                                onClick={() => handelDelete(index, data)}
                            >
                                <IoIosClose color="black" />
                            </Flex>
                        </Box>
                    );
                })}
            </Flex>
            <Button variant="solid" width={{ base: '100%', md: '25%' }} alignSelf="self-start" mt={6}>
                Save
            </Button>
        </VStack>
    );
};

export default Banners;
