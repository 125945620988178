import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-headers';

const usersApi = createApi({
    reducerPath: 'users',
    baseQuery: baseQuery,
    endpoints(builder) {
        return {
            removeAdminUser: builder.mutation({
                query: (id) => {
                    return {
                        url: `/users/admin_users/${id}`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: ['User'],
            }),
            addAdminUser: builder.mutation({
                query: (body) => {
                    return {
                        url: '/users/admin_users',
                        method: 'POST',
                        body: body,
                    };
                },
                invalidatesTags: ['User'],
            }),
            updateAdminUser: builder.mutation({
                query: (data) => {
                    return {
                        url: `/users/admin_users/${data?.id}`,
                        method: 'PUT',
                        body: data?.body,
                    };
                },
                invalidatesTags: ['User'],
            }),
            fetchUsers: builder.query({
                query: ({ user_type, filterObject }) => {
                    return {
                        url: `users/${user_type}`,
                        method: 'GET',
                        params: filterObject,
                    };
                },
                providesTags: ['User'],
            }),
            fetchUser: builder.query({
                query: ({ id, user_type }) => id && `/users/${'admin_user'}/${id}`,
                providesTags: (result, error, id) => [{ type: 'User', id }],
            }),
        };
    },
});

export const {
    useFetchUsersQuery,
    useAddAdminUserMutation,
    useUpdateAdminUserMutation,
    useFetchUserQuery,
    useRemoveAdminUserMutation,
} = usersApi;
export { usersApi };
