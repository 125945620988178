import { Flex, HStack, Text } from '@chakra-ui/react';
import React from 'react';

const UserType = ({ user }) => {
    return (
        <Flex
            gap={6}
            width="100%"
            flexWrap="wrap"
            flexDirection="column"
            bg="white"
            p="20px"
            borderRadius="10px"
            mt={6}
        >
            <HStack gap={14}>
                <Text fontSize="16px" color="rgba(129, 129, 129, 1)">
                    Type
                </Text>
                <Text fontSize="16px">{user?.privilege}</Text>
            </HStack>
        </Flex>
    );
};

export default UserType;
