import FaqForm from 'components/organisms/documents/faqForm';
import React, { useEffect, useState } from 'react';

const FaqTemplate = ({ list, isFetching }) => {
    const [faqs, setFaqs] = useState([]);
    useEffect(() => {
        setFaqs(list);
    }, [list, isFetching]);

    return !isFetching && <FaqForm setData={setFaqs} data={faqs} isFetching={isFetching} />;
};

export default FaqTemplate;
