import * as Yup from 'yup';
export const initialValues = (data) => ({
    ...data,
});
export const structure = (categoriesList, storesList, currenciesList) => [
    {
        name: `image`,
        kind: 'upload',
        type: 'text',
        label: 'Add photo h=200px,w=350px',
        width: '50%',
    },
    {
        name: `code`,
        kind: 'input',
        type: 'text',
        label: 'Code',
        width: '50%',
    },
    {
        name: `link`,
        kind: 'input',
        type: 'text',
        label: 'Product link',
        width: '50%',
    },
    {
        name: `name_en`,
        kind: 'input',
        type: 'text',
        label: 'Product name English',
        width: '50%',
    },
    {
        name: `name_ar`,
        kind: 'input',
        type: 'text',
        label: 'Product name Arabic',
        width: '50%',
    },
    {
        name: `description_en`,
        kind: 'textArea',
        type: 'text',
        label: 'Product Description English',
        width: '50%',
    },
    {
        name: `description_ar`,
        kind: 'textArea',
        type: 'text',
        label: 'Product Description Arabic',
        width: '50%',
    },
    {
        name: `store_id`,
        kind: 'select',
        type: 'text',
        label: 'Store',
        width: '50%',
        isMulti: false,
        options: storesList,
    },
    {
        name: `category_id`,
        kind: 'select',
        type: 'text',
        label: 'Category',
        width: '50%',
        isMulti: false,
        options: categoriesList,
    },
    {
        name: `currency_id`,
        kind: 'select',
        type: 'text',
        label: 'Currency',
        width: '50%',
        isMulti: false,
        options: currenciesList,
    },
    {
        name: `is_visible`,
        kind: 'checkbox',
        type: 'text',
        label: 'In Home',
        width: '50%',
    },
    {
        name: `is_top_deal `,
        kind: 'checkbox',
        type: 'text',
        label: 'Is Top Product',
        width: '50%',
    },
];
export const validationSchema = () =>
    Yup.object({
        // name_en: Yup.string().required('required'),
        // name_ar: Yup.string().required('required'),
    });
