import React, { useMemo, useTransition } from 'react';
import Footer from './footer';
import Header from './header';

import { Checkbox, Stack, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Text, VStack } from '@chakra-ui/react';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

const MainTable = ({
    Data,
    Columns,
    isChecked,
    isSearch,
    isFilter,
    searchFun,
    selectedData,
    currentpage,
    totalPage,
    setPageNumber,
    checked,
    setChecked,
    createBtnTitle,
    createBtnFun,
    placeholder,
    filterList,
    pagesNumber,
    title,
    pageNumber,
}) => {
    const [isPending, startTransition] = useTransition();

    const handleAllCheckboxChange = (event) => {
        let checkedData = [];
        for (let key = 0; key < data?.length; key++) {
            checkedData.push(event?.target?.checked);
        }
        setChecked(() => checkedData);
    };

    const handleCheckboxChange = (event, index) => {
        startTransition(() => {
            const indexData = index ? index : 0;
            let data = checked;
            data[`${indexData}`] = event?.target?.checked;
            setChecked(data);
        });
    };
    const allChecked = checked?.every(Boolean);
    const isIndeterminate = checked?.some(Boolean) && !allChecked;

    const data = useMemo(
        () =>
            Data &&
            Data?.map((value) => {
                return {
                    ...value,
                };
            }),
        [Data],
    );

    const columns = useMemo(
        () =>
            Columns?.map((column) => {
                return {
                    Header: column.Header,
                    accessor: column.accessor,
                    sort: column.sort,
                };
            }),
        [Columns],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            useControlledState: (state) => {
                return React.useMemo(
                    () => ({
                        ...state,
                        pageIndex: currentpage,
                    }),
                    [state, currentpage],
                );
            },
            // @ts-ignore
            initialState: { pageIndex: currentpage },
            manualPagination: true,
            pageCount: totalPage,
            autoResetHiddenColumns: false,
            autoResetSortBy: false,
            autoResetPage: false,
        },

        useGlobalFilter,
        useSortBy,
        usePagination,
    );
    return (
        <VStack width="100%">
            <Header
                isSearch={isSearch}
                isFilter={isFilter}
                Data={Data}
                searchFun={searchFun}
                createBtnTitle={createBtnTitle}
                createBtnFun={createBtnFun}
                placeholder={placeholder}
                filterList={filterList}
                title={title}
            />

            {Data?.length == 0 ? (
                <Text fontSize="18px" fontWeight="600">
                    No Data Available
                </Text>
            ) : (
                <TableContainer sx={{ borderRadius: '12px', padding: '15px', width: '100%', bg: 'white' }}>
                    <Table {...getTableProps()} bg="white" variant="simple">
                        <Thead color="main_font">
                            {headerGroups.map((headerGroup, index) => (
                                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                    {isChecked && (
                                        <Th display="flex">
                                            <Checkbox
                                                isChecked={isPending ? allChecked : allChecked}
                                                isIndeterminate={isIndeterminate}
                                                onChange={(e) => handleAllCheckboxChange(e)}
                                            />
                                            <Text mx={1}>No.</Text>
                                        </Th>
                                    )}
                                    {headerGroup.headers.map((column, index) => (
                                        <Th
                                            key={index}
                                            bg="inherit"
                                            {...column.getHeaderProps(
                                                column.Header === '' ? '' : column.getSortByToggleProps(),
                                            )}
                                        >
                                            <Stack direction="row">
                                                {Data.length > 0 ? (
                                                    <>
                                                        <Text fontWeight="500" fontSize="14px">
                                                            {column.render('Header')}
                                                        </Text>
                                                        {column.Header === '' ? (
                                                            ''
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    paddingLeft: '10px',
                                                                    margin: '-1px',
                                                                }}
                                                            >
                                                                {column.sort &&
                                                                    (column.isSorted ? (
                                                                        column.isSortedDesc ? (
                                                                            <FaSortUp
                                                                                aria-label="sorted descending"
                                                                                fontSize="20px"
                                                                            />
                                                                        ) : (
                                                                            <FaSortDown
                                                                                aria-label="sorted ascending"
                                                                                fontSize="20px"
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        <FaSort fontSize="20px" />
                                                                    ))}
                                                            </span>
                                                        )}
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </Stack>
                                        </Th>
                                    ))}
                                </Tr>
                            ))}
                        </Thead>
                        {Data?.length > 0 ? (
                            <Tbody {...getTableBodyProps()} color="main_font">
                                {page.map((row, index) => {
                                    prepareRow(row);
                                    return (
                                        <Tr
                                            key={index}
                                            {...row.getRowProps()}
                                            onClick={() => selectedData(row.original)}
                                        >
                                            {isChecked && (
                                                <Td
                                                    sx={{
                                                        color: 'main_font',
                                                        fontweight: '400',
                                                        fontsize: '14px',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <Stack flexDirection="row">
                                                        <Checkbox
                                                            isChecked={isPending ? checked[index] : checked[index]}
                                                            onChange={(e) => handleCheckboxChange(e, index)}
                                                        />
                                                        <Text mx={1}>{index + 1}</Text>
                                                    </Stack>
                                                </Td>
                                            )}
                                            {row.cells.map((cell, index) => {
                                                return (
                                                    <Td
                                                        key={index}
                                                        sx={{
                                                            color: 'main_font',
                                                            fontweight: '400',
                                                            fontsize: '14px',
                                                            position: 'relative',
                                                        }}
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render('Cell')}
                                                    </Td>
                                                );
                                            })}
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        ) : (
                            ''
                        )}
                    </Table>
                    {Data.length > 0 ? (
                        <Footer
                            setPageNumber={setPageNumber}
                            pageIndex={pageIndex}
                            totalPage={totalPage}
                            pagesNumber={pagesNumber}
                            pageNumber={pageNumber}
                        />
                    ) : (
                        ''
                    )}
                </TableContainer>
            )}
        </VStack>
    );
};

export default MainTable;
