import {
    Button,
    Checkbox,
    CheckboxGroup,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { GrFormAdd } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateCategoryMutation } from 'services/apis/categoryApi';
import { openDeactivateProfile } from 'services/apis/models';

const DeactivateProfile = ({ data }) => {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.models.deactivateProfile);
    const [updateCategory, updateCategoryResults] = useUpdateCategoryMutation();

    const handelClose = () => {
        dispatch(openDeactivateProfile());
    };

    const handelDeactivate = () => {
        const body = {
            category: { ...data },
        };

        updateCategory({ id: data?.id, body });
        updateCategoryResults?.isSuccess && handelClose();
    };
    return (
        <Modal isOpen={open} onClose={handelClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody px="20px">
                    <VStack width="100%" alignItems="start" gap={8} py={4}>
                        <Text fontSize="20px" fontWeight="600" textAlign="center">
                            Are you sure you want to Deactivate your account
                        </Text>

                        <Flex
                            flexDir={{ base: 'column', md: 'row' }}
                            width="100%"
                            alignItems="center"
                            justifyContent="center"
                            gap={2}
                        >
                            <Button
                                colorScheme="blue"
                                onClick={handelClose}
                                variant="outline"
                                width={{ base: '100%', md: '45%' }}
                            >
                                Cancel
                            </Button>
                            <Button onClick={handelDeactivate} variant="solid" width={{ base: '100%', md: '45%' }}>
                                Yes, Deactivate
                            </Button>
                        </Flex>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default DeactivateProfile;
