import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-headers';

const profileApi = createApi({
    reducerPath: 'profile',
    baseQuery: baseQuery,
    endpoints(builder) {
        return {
            deactivateProfile: builder.mutation({
                query: (id) => {
                    return {
                        url: `/promocodes/${id}`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: ['Profile'],
            }),
            changePassword: builder.mutation({
                query: (body) => {
                    return {
                        url: '/auth/passwords/change_password',
                        method: 'PUT',
                        body: body,
                    };
                },
                invalidatesTags: ['Profile'],
            }),
            updateProfile: builder.mutation({
                query: (data) => {
                    return {
                        url: `/promocodes/${data?.id}`,
                        method: 'PUT',
                        body: data?.body,
                    };
                },
                invalidatesTags: ['Profile'],
            }),

            fetchProfile: builder.query({
                query: (id) => `/promocodes/${id}`,
                providesTags: (result, error, id) => [{ type: 'Profile', id }],
            }),
        };
    },
});

export const {
    useChangePasswordMutation,
    useUpdateProfileMutation,
    useFetchProfileQuery,
    useDeactivateProfileMutation,
} = profileApi;
export { profileApi };
