import { Divider, Flex, HStack, Text } from '@chakra-ui/react';
import React from 'react';

const CategoryData = ({ data }) => {
    const list = [
        { label: 'Number of Stores', value: data?.stores_count },
        { label: 'Number of Products', value: data?.products_count },
        { label: 'Number of Copouns', value: data?.promocodes_count },
    ];
    return (
        <Flex gap={6} width="100%" flexWrap="wrap" flexDirection="column" bg="white" p="20px" borderRadius="10px">
            {list?.map((data, index) => {
                return (
                    <>
                        <HStack key={index} gap={14}>
                            <Text fontSize="16px" color="rgba(129, 129, 129, 1)">
                                {data?.label}
                            </Text>
                            <Text fontSize="16px">{data?.value}</Text>
                        </HStack>
                        {index != list?.length - 1 && <Divider width={'100%'} />}
                    </>
                );
            })}
        </Flex>
    );
};

export default CategoryData;
