import { Checkbox, Flex, HStack, VStack } from '@chakra-ui/react';
import CEditableInput from 'common/forms/components/CEditableInput';
import CFormLabels from 'common/forms/components/CFormLabels';
import React from 'react';
import { useUpdateCouponMutation } from 'services/apis/couponsApi';
import Select from 'react-select';

const UpdateForm = ({ data }) => {
    const [UpdateCoupon] = useUpdateCouponMutation();

    const handelUpdate = (val, name) => {
        const body = {
            promocode: { ...data, [`${name}`]: val },
        };

        UpdateCoupon({ id: data?.id, body });
    };
    return (
        <Flex
            gap={6}
            width="100%"
            flexWrap="wrap"
            flexDirection="column"
            bg="white"
            p="20px"
            borderRadius="10px"
            mt={6}
        >
            <CEditableInput defaultValue={data?.name} name="name" handelSubmit={handelUpdate} label="Coupon name" />
            <CEditableInput
                defaultValue={data?.description_en}
                name="description_en"
                handelSubmit={handelUpdate}
                label="Description in English"
            />
            <CEditableInput
                defaultValue={data?.description_ar}
                name="description_ar"
                handelSubmit={handelUpdate}
                label="Description in Arabic"
            />
            <VStack alignItems="start" width="50%">
                <CFormLabels label="Coupon For" />

                <Select
                    styles={{ width: '100%' }}
                    options={[
                        { label: 'all', value: 0 },
                        { label: 'web', value: 1 },
                        { label: 'mobile', value: 2 },
                    ]}
                    onChange={(value) => handelUpdate(value?.value, 'for')}
                    className="search-select"
                    placeholder={data?.for}
                />
            </VStack>
            <HStack mb="5">
                <Checkbox
                    defaultChecked={data?.is_visible}
                    lineHeight="1.25"
                    alignItems="flex-start"
                    fontWeight="normal"
                    size="md"
                    onChange={(e) => handelUpdate(e.target.checked, 'is_visible')}
                />
                <CFormLabels label="In Home" />
            </HStack>
            <HStack mb="5">
                <Checkbox
                    defaultChecked={data?.is_top_deal}
                    lineHeight="1.25"
                    alignItems="flex-start"
                    fontWeight="normal"
                    size="md"
                    onChange={(e) => handelUpdate(e.target.checked, 'is_top_deal')}
                />
                <CFormLabels label="Is Top Store" />
            </HStack>
        </Flex>
    );
};

export default UpdateForm;
