import { Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFetchCurrenciesQuery } from 'services/apis/currenciesApi';
import CurrenciesTemplate from 'templates/currencies';

const Currencies = ({ setTitle }) => {
    const [filterObject, setFilterObject] = useState({
        page_number: 1,
        keyword: '',
    });

    const { data, error, isLoading } = useFetchCurrenciesQuery(filterObject);
    const list = data?.data?.currencies;
    const count = Math.ceil(data?.extra?.total_count / 10);

    useEffect(() => {
        setTitle('Currencies');
    }, []);
    return isLoading ? <Spinner /> : <CurrenciesTemplate count={count} list={list} setFilterObject={setFilterObject} />;
};

export default Currencies;
