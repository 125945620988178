import {
    Button,
    Checkbox,
    CheckboxGroup,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { GrFormAdd } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateCategoryMutation } from 'services/apis/categoryApi';
import { openAddCategoryStores } from 'services/apis/models';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddStores = ({ stores, data }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [storesList, setStoreslist] = useState([]);
    const open = useSelector((state) => state.models.addCategoryStores);
    const [updateCategory, updateCategoryResults] = useUpdateCategoryMutation();

    const handelClose = () => {
        dispatch(openAddCategoryStores());
    };

    const handelAddStores = () => {
        const body = {
            category: { ...data, store_ids: storesList },
        };

        updateCategory({ id: data?.id, body }).then((res) => {
            if (res?.error) toast.error(res?.error?.data?.message);
            else handelClose();
        });
    };
    const storename = stores?.find((data) => data?.id == storesList?.[0]);
    console.log(stores);
    return (
        <Modal isOpen={open} onClose={handelClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody px="20px">
                    <Text fontSize="20px" fontWeight="600" py="20px">
                        Add Store
                    </Text>

                    <VStack width="100%" alignItems="start" gap={8}>
                        <VStack width="100%" alignItems="start" gap={2}>
                            <Text fontSize="16px">Choose stores</Text>
                            <Menu closeOnSelect={false}>
                                <MenuButton
                                    as={Button}
                                    rightIcon={<IoIosArrowDown />}
                                    sx={{
                                        width: '100%',
                                        border: '1px solid #D9D9D9',
                                        borderRadius: '4px',
                                        justifyContent: 'space-between',
                                        textAlign: 'start',
                                    }}
                                >
                                    {storename
                                        ? ` ${storename?.name} +${storesList?.length - 1} Other`
                                        : 'Choose stores'}
                                </MenuButton>
                                <MenuList style={{ minWidth: '395px', width: '100%' }}>
                                    <MenuItem _hover={{ background: 'transparent' }}>
                                        <CheckboxGroup
                                            colorScheme="green"
                                            sx={{ width: '100%' }}
                                            onChange={(val) => setStoreslist(val)}
                                            defaultValue={data?.categories?.map((data) => data?.id)}
                                        >
                                            <Stack spacing={[5]} direction={['column']}>
                                                {stores?.map((data, index) => {
                                                    return (
                                                        <Checkbox value={data?.id?.toString()} key={index}>
                                                            {data?.name}
                                                        </Checkbox>
                                                    );
                                                })}
                                            </Stack>
                                        </CheckboxGroup>
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </VStack>
                        <Button
                            mr={3}
                            onClick={() => {
                                navigate('/stores/add-new-store');
                                handelClose();
                            }}
                            variant="outline"
                            // leftIcon={<GrFormAdd style={{ color: '#FF9700' }} />}
                        >
                            Add a New Store
                        </Button>
                        <Flex
                            flexDir={{ base: 'column', md: 'row' }}
                            width="100%"
                            alignItems="center"
                            justifyContent="center"
                            gap={2}
                        >
                            <Button
                                colorScheme="blue"
                                onClick={handelClose}
                                variant="outline"
                                width={{ base: '100%', md: '49%' }}
                            >
                                Cancel
                            </Button>
                            <Button onClick={handelAddStores} variant="solid" width={{ base: '100%', md: '49%' }}>
                                Save
                            </Button>
                        </Flex>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AddStores;
