import React from 'react';
import FormIndex from 'common/forms';
import { useFormik } from 'formik';
import { Button, Toast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAddCategoryMutation } from 'services/apis/categoryApi';
import { structure } from 'components/organisms/coupons/forms/add-form';
import { useAddCouponMutation } from 'services/apis/couponsApi';
import { toast } from 'react-toastify';
const CreateCouponTemplate = ({ stores }) => {
    const [addCoupon, { isLoading }] = useAddCouponMutation();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: { is_visible: true, is_top_deal: true },
        onSubmit: (values) => {
            addCoupon({ promocode: { ...values, store_id: values?.store_id?.value, for: values?.for?.value } }).then(
                (res) => {
                    if (res?.error) toast.error(res?.error?.data?.message);
                    else navigate('/coupons');
                },
            );
        },
    });

    return (
        <FormIndex formStructures={structure(stores)} formik={formik}>
            <Button
                isLoading={isLoading}
                type="submit"
                variant="solid"
                width={{ base: '100%', md: '25%' }}
                alignSelf="self-start"
            >
                Save
            </Button>
        </FormIndex>
    );
};

export default CreateCouponTemplate;
