import { VStack, Image, Text, HStack, Icon, Divider, Box } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineSetting } from 'react-icons/ai';
import { FaListUl, FaUsers } from 'react-icons/fa';
import { BsTag, BsGrid } from 'react-icons/bs';
import { IoStorefront, IoNotificationsOutline } from 'react-icons/io5';
import { LuLogOut } from 'react-icons/lu';
import { GoHomeFill } from 'react-icons/go';
import { PiCurrencyDollarBold } from 'react-icons/pi';
import { NavLink, useNavigate } from 'react-router-dom';
import { useLogoutMutation } from 'services/apis/authApi';
import { toast } from 'react-toastify';
import { MdOutlineAccountBalance } from 'react-icons/md';
import { TbSlideshow } from 'react-icons/tb';
import { IoDocumentTextOutline } from 'react-icons/io5';

const Sidenav = ({ user }) => {
    const navigate = useNavigate();
    const [logout] = useLogoutMutation();
    const handelLogout = () => {
        logout({}).then((res) => {
            navigate('/auth/login');
            setTimeout(() => {
                localStorage.removeItem('token');
            }, 500);
        });
    };
    const links = [
        {
            path: '/',
            icon: GoHomeFill,
            label: 'Home',
        },
        {
            path: '/categories',
            icon: FaListUl,
            label: 'Categories',
        },
        {
            path: '/coupons',
            icon: BsTag,
            label: 'Coupons',
        },
        {
            path: '/stores',
            icon: IoStorefront,
            label: 'Stores',
        },
        {
            path: '/products',
            icon: BsGrid,
            label: 'Products',
        },
        {
            path: '/banners',
            icon: TbSlideshow,
            label: 'Banners',
        },
        {
            path: '/documents',
            icon: IoDocumentTextOutline,
            label: 'Documents',
        },
        {
            path: '/users',
            icon: FaUsers,
            label: 'Users',
        },
        {
            path: '/admins',
            icon: AiOutlineSetting,
            label: 'Admins',
        },
        {
            path: '/notifications',
            icon: IoNotificationsOutline,
            label: 'Notifications',
        },
        {
            path: '/countries',
            icon: MdOutlineAccountBalance,
            label: 'Countries',
        },
        {
            path: '/currencies',
            icon: PiCurrencyDollarBold,
            label: 'Currencies',
        },
        {
            path: '/',
            icon: LuLogOut,
            label: 'Log out',
            action: handelLogout,
        },
    ];
    const editorLinks = [
        {
            path: '/',
            icon: GoHomeFill,
            label: 'Home',
        },

        {
            path: '/coupons',
            icon: BsTag,
            label: 'Coupons',
        },

        {
            path: '/auth/login',
            icon: LuLogOut,
            label: 'Log out',
            action: handelLogout,
        },
    ];
    return (
        <VStack alignItems="start" p={{ base: 2, md: 6 }} gap={6} width="100%" minH="100vh">
            <Image
                src={require('assets/images/logo.png')}
                width={{ base: '100%', md: '166px' }}
                height={{ base: '100%', md: '55px' }}
                my="40px"
            />
            {user?.privilege != 'editor' &&
                links?.map((data, index) => {
                    return (
                        <Box
                            as={NavLink}
                            to={data?.path}
                            sx={{
                                color: 'main_dark_color',
                            }}
                            style={({ isActive }) => ({
                                width: '100%',
                                padding: '10px',
                                color: isActive ? '#fff' : 'inherit',
                                background: isActive ? '#FF9700' : 'transparent',
                                borderRadius: '4px',
                                position: 'relative',
                            })}
                            onClick={() => data?.action && data?.action()}
                        >
                            {({ isActive, isPending }) => (
                                <HStack key={index} gap={4}>
                                    {isActive && (
                                        <Divider
                                            orientation="vertical"
                                            sx={{
                                                opacity: 1,
                                                position: 'absolute',
                                                width: '8px',
                                                height: '47px',
                                                left: { base: '-13', md: '-25' },
                                                bgColor: '#FF9700',
                                                borderTopRightRadius: '4px',
                                                borderEndEndRadius: '4px',
                                            }}
                                        />
                                    )}
                                    <Icon as={data?.icon} width="25px" height="25px" />
                                    <Text
                                        fontSize="18px"
                                        fontWeight="500"
                                        color="inherit"
                                        display={{ base: 'none', md: 'block' }}
                                    >
                                        {data?.label}
                                    </Text>
                                </HStack>
                            )}
                        </Box>
                    );
                })}

            {user?.privilege == 'editor' &&
                editorLinks?.map((data, index) => {
                    return (
                        <Box
                            as={NavLink}
                            to={data?.path}
                            sx={{
                                color: 'main_dark_color',
                            }}
                            style={({ isActive }) => ({
                                width: '100%',
                                padding: '10px',
                                color: isActive ? '#fff' : 'inherit',
                                background: isActive ? '#FF9700' : 'transparent',
                                borderRadius: '4px',
                                position: 'relative',
                            })}
                            onClick={() => data?.action && data?.action()}
                        >
                            {({ isActive, isPending }) => (
                                <HStack key={index} gap={4}>
                                    {isActive && (
                                        <Divider
                                            orientation="vertical"
                                            sx={{
                                                opacity: 1,
                                                position: 'absolute',
                                                width: '8px',
                                                height: '47px',
                                                left: { base: '-13', md: '-25' },
                                                bgColor: '#FF9700',
                                                borderTopRightRadius: '4px',
                                                borderEndEndRadius: '4px',
                                            }}
                                        />
                                    )}
                                    <Icon as={data?.icon} width="25px" height="25px" />
                                    <Text
                                        fontSize="18px"
                                        fontWeight="500"
                                        color="inherit"
                                        display={{ base: 'none', md: 'block' }}
                                    >
                                        {data?.label}
                                    </Text>
                                </HStack>
                            )}
                        </Box>
                    );
                })}
        </VStack>
    );
};

export default Sidenav;
