import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';

export const baseQuery = fetchBaseQuery({
    baseUrl: 'https://api.xdeal.net/api/v1',
    prepareHeaders: (headers, { getState }) => {
        const token = localStorage.getItem('token');
        const language = localStorage.getItem('language') || 'en';
        const country = localStorage.getItem('country') || 'Egypt';

        headers.set('Accept-Language', language);
        headers.set('Country', country);
        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }

        return headers;
    },
});
