import React, { useEffect, useState } from 'react';
import MainTable from 'common/table/index';
import { Flex, Image, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Actions from 'common/table/actions';
import { useRemoveProductMutation, useUpdateProductMutation } from 'services/apis/productsApi';
import dayjs from 'dayjs';

const StoreProducts = ({ products, setProductFilter, productsCount }) => {
    const navigate = useNavigate();
    const [updateProduct] = useUpdateProductMutation();
    const [removeProduct] = useRemoveProductMutation();

    const actionsList = (item) => [
        {
            type: 'active',
            func: () => {
                updateProduct({
                    id: item?.id,
                    body: { product: { is_visible: !item?.is_visible } },
                });
            },
        },
        {
            type: 'edit',
            func: () => {
                navigate(`/stores/update-store/${item?.id}`);
            },
        },
        {
            type: 'delete',
            func: () => {
                removeProduct(item?.id);
            },
        },
    ];

    const createBtnFun = (val) => {
        //console.log(val, pageNumber);
        navigate('/products/add-new-product');
    };
    const [checked, setChecked] = useState([false]);
    const [perPage, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);

    const mappedList = products?.map((item) => {
        return {
            ...item,
            date: <Text color="main_font">{dayjs(item?.created_at).format('DD/MM/YYYY')}</Text>,
            priceBefore: `${item?.price} ${item?.currency?.iso_code}`,
            priceAfter: `${item?.price_after_discount} ${item?.currency?.iso_code}`,
            action: <Actions list={actionsList(item)} type="not-menu" item={item} />,
            image: <Image src={item?.image} width="35px" height="25px" />,
            inhome: item?.is_visible ? <Text color="#3BA733">Yes</Text> : <Text color="#EB0000">No</Text>,
        };
    });
    useEffect(() => {
        setProductFilter((s) => {
            return { ...s, page_number: pageNumber };
        });
    }, [pageNumber]);
    return (
        <Flex gap={6} width="100%" flexWrap="wrap" flexDirection="column" bg="white" p="20px" borderRadius="10px">
            <MainTable
                title="Products"
                Data={mappedList || []}
                Columns={[
                    {
                        Header: 'Image',
                        accessor: 'image',
                    },
                    {
                        Header: 'Old price',
                        accessor: 'priceBefore',
                    },
                    {
                        Header: 'New Price',
                        accessor: 'priceAfter',
                    },
                    {
                        Header: 'In home',
                        accessor: 'inhome',
                    },
                    {
                        Header: 'created dates',
                        accessor: 'date',
                    },
                    {
                        Header: 'Action',
                        accessor: 'action',
                    },
                ]}
                isChecked
                isSearch={true}
                isFilter={false}
                searchFun={(val) =>
                    setProductFilter((s) => {
                        return { ...s, keyword: val };
                    })
                }
                selectedData={() => {}}
                currentpage={1}
                setPage={() => {}}
                perPage={perPage}
                totalPage={1}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                checked={checked}
                setChecked={setChecked}
                setPerPage={setPerPage}
                createBtnTitle="Add a New Product"
                createBtnFun={createBtnFun}
                placeholder="Search Products..."
                filterList={<></>}
                pagesNumber={productsCount == 0 ? 1 : productsCount}
            />
        </Flex>
    );
};

export default StoreProducts;
