import React from 'react';
import { VStack } from '@chakra-ui/react';
import UpdateForm from 'components/organisms/currencies/forms/update-form';

const UpdateCurrencyTemplate = ({ data }) => {
    return (
        <VStack w="95%" gap={6} mb={6}>
            <UpdateForm data={data} />
        </VStack>
    );
};

export default UpdateCurrencyTemplate;
