import React from 'react';
import FormIndex from 'common/forms';
import { useFormik } from 'formik';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAddNotificationMutation } from 'services/apis/notificationsApi';
import { structure } from 'components/organisms/notifications/forms/add-form';
const CreateNotificationTemplate = ({}) => {
    const [addNotification, { isLoading }] = useAddNotificationMutation();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {},

        onSubmit: (values) => {
            addNotification({ notification: { ...values } }).then((res) => {
                if (res?.error) toast.error(res?.error?.data?.message);
                else navigate('/notifications');
            });
        },
    });

    return (
        <FormIndex formStructures={structure()} formik={formik}>
            <Button
                isLoading={isLoading}
                type="submit"
                variant="solid"
                width={{ base: '100%', md: '25%' }}
                alignSelf="self-start"
            >
                Save
            </Button>
        </FormIndex>
    );
};

export default CreateNotificationTemplate;
