import { Button, Modal, ModalBody, ModalContent, ModalOverlay, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openChangePasswordModel } from 'services/apis/models';
import FormIndex from 'common/forms';
import { useFormik } from 'formik';
import { structure } from './structure';
import { useChangePasswordMutation } from 'services/apis/profileApi';
import { toast } from 'react-toastify';

const ChangePassword = () => {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.models.changePassword);
    const [changePassword, changePasswordResults] = useChangePasswordMutation();

    const formik = useFormik({
        initialValues: {},
        onSubmit: (values) => {
            changePassword({ user: { ...values } }).then((res) => {
                if (res?.error) toast.error(res?.error?.data?.message);
                else handelClose();
            });
        },
    });
    const handelClose = () => {
        dispatch(openChangePasswordModel());
        formik?.resetForm();
    };
    return (
        <Modal isOpen={open} onClose={handelClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody p="0">
                    <Text fontSize="20px" fontWeight="600" px="20px" pt="20px">
                        Change Password
                    </Text>
                    <FormIndex width="100%" formStructures={structure()} formik={formik}>
                        <Stack flexDirection={{ base: 'column', md: 'row' }} width="100%">
                            <Button onClick={handelClose} variant="outline" width={{ base: '100%', md: '48%' }}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="solid" width={{ base: '100%', md: '48%' }}>
                                Save
                            </Button>
                        </Stack>
                    </FormIndex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ChangePassword;
