import * as Yup from 'yup';
export const initialValues = (data) => ({
    ...data,
});
export const structure = () => [
    {
        name: `email`,
        kind: 'input',
        type: 'text',
        label: 'Email',
        width: '100%',
    },
    {
        name: `password`,
        kind: 'input',
        type: 'password',
        label: 'Password',
        width: '100%',
    },
];
export const validationSchema = () =>
    Yup.object({
        // name_en: Yup.string().required('required'),
        // name_ar: Yup.string().required('required'),
    });
