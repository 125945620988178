import { Button, Input, Text, Textarea, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useUpdateContactsMutation } from 'services/apis/documents';
import loadsh from 'lodash';
const ContactusTemplate = ({ list }) => {
    const [contacts, setContacts] = useState([]);
    const [updateContacts] = useUpdateContactsMutation();
    const unincludedData = ['tiktok-pixel', 'snapchat-pixel', 'twitter-pixel', 'googletag-pixel', 'facebook-pixel'];

    useEffect(() => {
        setContacts(list);
    }, [list]);
    const handelUpdate = (id, key, value) => {
        updateContacts({
            id: id,
            body: {
                contact: {
                    key: key,
                    value: value,
                },
            },
        });
    };

    const handelChange = (data, index) => {
        let newData = [...contacts]; // Make a shallow copy of the contacts array
        newData[index] = { ...newData[index], value: data }; // Create a new object with updated value
        setContacts(newData);
    };

    return (
        <VStack width="100%" gap={4}>
            {contacts?.map((data, index) => {
                if (!unincludedData?.includes(data?.key))
                    return (
                        <VStack
                            p={6}
                            alignItems="start"
                            bgColor="rgba(255, 255, 255, 1)"
                            borderRadius="8px"
                            width="100%"
                        >
                            <Text fontSize="16px" fontWeight="5oo" color="rgba(129, 129, 129, 1)">
                                {data?.key}
                            </Text>
                            <Textarea
                                onChange={(e) => handelChange(e.target.value, index)}
                                rows={4}
                                width={{ base: '100%', md: '50%' }}
                                value={data?.value}
                            />
                            <Button
                                mt={2}
                                variant="solid"
                                width={{ base: '80%', md: '20%' }}
                                onClick={() => handelUpdate(data?.id, data?.key, contacts[index]?.value)}
                            >
                                Save
                            </Button>
                        </VStack>
                    );
            })}
        </VStack>
    );
};

export default ContactusTemplate;
