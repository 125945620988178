import React from 'react';
import { VStack } from '@chakra-ui/react';
import UpdateForm from 'components/organisms/categories/forms/update-form';
import CategoryData from 'components/organisms/categories/components/category-data';
import CategoryStores from 'components/organisms/categories/components/category-stores';
import AddStores from 'components/organisms/categories/models/add-stores';
const UpdateCategoryTemplate = ({ data, storesList, setFilterObject, storesCount }) => {
    return (
        <VStack w={{ base: '100%', md: '90%' }} gap={6} mb={6}>
            <UpdateForm data={data} />
            <CategoryData data={data} />
            <CategoryStores stores={storesList} setFilterObject={setFilterObject} storesCount={storesCount} />
            <AddStores stores={storesList} data={data} />
        </VStack>
    );
};

export default UpdateCategoryTemplate;
