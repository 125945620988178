import * as Yup from 'yup';
export const initialValues = (data) => ({
    ...data,
});
export const structure = (categoriesList) => [
    {
        name: `logo`,
        kind: 'upload',
        type: 'text',
        label: 'Add photo h=150px,w=230px',
        width: '50%',
    },
    {
        name: `name_en`,
        kind: 'input',
        type: 'text',
        label: 'Store name English',
        width: '50%',
    },
    {
        name: `name_ar`,
        kind: 'input',
        type: 'text',
        label: 'Store name Arabic',
        width: '50%',
    },
    {
        name: `description_en`,
        kind: 'textArea',
        type: 'text',
        label: 'Store Description English',
        width: '50%',
    },
    {
        name: `description_ar`,
        kind: 'textArea',
        type: 'text',
        label: 'Store Description Arabic',
        width: '50%',
    },
    {
        name: `link`,
        kind: 'input',
        type: 'text',
        label: 'Store link',
        width: '50%',
    },
    {
        name: `category_ids`,
        kind: 'select',
        type: 'text',
        label: 'Categories',
        width: '50%',
        isMulti: true,
        options: categoriesList,
    },
    {
        name: `is_visible`,
        kind: 'checkbox',
        type: 'text',
        label: 'In Home',
        width: '50%',
    },
];
export const validationSchema = () =>
    Yup.object({
        // name_en: Yup.string().required('required'),
        // name_ar: Yup.string().required('required'),
    });
