import { Button, Grid, Image, Input, Stack, Text } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BiImageAdd } from 'react-icons/bi';
import { useUploadPhotoMutation, useUploadS3Mutation } from 'services/apis/upload';

function CUpload({ type, id, label, changeAction, value, direction = 'row', style }) {
    const [uploadS3, addUploadS3Results] = useUploadS3Mutation();
    const [uploadPhoto, { isLoading }] = useUploadPhotoMutation();

    const [fileUrl, setFileUrl] = useState(null);

    useEffect(() => {
        if (type === 'edit') {
            setFileUrl(value);
        }
    }, [value, type]);
    const uploadFile = async (event) => {
        const body = {
            files: [
                {
                    extension: event?.target?.files[0]?.type?.split('/')?.[1],
                },
            ],
        };
        uploadS3(body).then((data) => {
            const body = {
                url: data?.data?.data?.[0]?.presigned_url,
                body: event?.target?.files[0],
            };

            uploadPhoto(body).then(() => {
                changeAction(data?.data?.data?.[0]?.file_url);
                type != 'banner' && setFileUrl(data?.data?.data?.[0]?.file_url);
            });
        });
    };
    return (
        <Stack width="100%" flexDirection={{ base: 'column', md: direction }} alignItems="center">
            <Stack
                bg="#E4E4E4"
                borderRadius="4px"
                position="relative"
                width="113px"
                height="81px"
                alignItems="center"
                justifyContent="center"
                style={style}
            >
                {isLoading ? (
                    <Spinner />
                ) : fileUrl ? (
                    <Image src={fileUrl} width="100%" height="100%" />
                ) : (
                    <BiImageAdd style={{ width: '50px', height: '50px', cursor: 'pointer' }} />
                )}
                <label
                    htmlFor={`upload-${id}`}
                    style={{ top: '0', position: 'absolute', width: '100%', height: '100%' }}
                >
                    <input
                        id={`upload-${id}`}
                        name={`upload-${id}`}
                        type="file"
                        style={{ width: '100%', height: '100%', top: '0', position: 'absolute', zIndex: '-1' }}
                        onChange={(e) => uploadFile(e)}
                    />
                </label>
            </Stack>

            <Text fontSize="18px" fontWeight="500" textAlign="center">
                {label}
            </Text>
        </Stack>
    );
}

export default CUpload;
