import { HStack, Stack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import CreateCountriesTemplate from 'templates/countries/create';

const CreateCountry = ({ setTitle }) => {
    useEffect(() => {
        const title = (
            <HStack>
                <NavLink to="/countries">Countries</NavLink>
                <IoIosArrowForward />
                <Text>Add new Country</Text>
            </HStack>
        );
        setTitle(title);
    }, []);
    return <CreateCountriesTemplate />;
};

export default CreateCountry;
