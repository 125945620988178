import * as Yup from 'yup';
export const initialValues = (data) => ({
    ...data,
});
export const structure = () => [
    {
        name: `name`,
        kind: 'input',
        type: 'text',
        label: 'Coupon name',
        width: '100%',
    },
    {
        name: `description_en`,
        kind: 'textArea',
        type: 'text',
        label: 'Description in English',
        width: '100%',
    },
    {
        name: `description_ar`,
        kind: 'textArea',
        type: 'text',
        label: 'Description in Arabic',
        width: '100%',
    },
];
export const validationSchema = () =>
    Yup.object({
        // name_en: Yup.string().required('required'),
        // name_ar: Yup.string().required('required'),
    });
