import { Button, HStack, Input, Text, Textarea, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useAddFaqMutation, useRemoveFaqMutation, useUpdateFaqMutation } from 'services/apis/documents';
import { IoCloseCircle } from 'react-icons/io5';

const FaqForm = ({ data, isFetching }) => {
    const [inputsLength, setInputsLength] = useState(0);
    const [faq, setFaq] = useState();
    const [faqs, setFaqs] = useState();

    const [updateFaq, { isLoading }] = useUpdateFaqMutation();
    const [addFaq] = useAddFaqMutation();
    const [removeFaq] = useRemoveFaqMutation();

    useEffect(() => {
        setInputsLength(data?.length);
        setFaqs(data);
    }, [data]);
    const handelDelete = (index) => {
        removeFaq(data?.[index]?.id);
    };
    const handelSave = (index) => {
        if (data?.[index]?.id) {
            updateFaq({
                id: data?.[index]?.id,
                body: {
                    faq: { ...data?.[index], ...faq },
                },
            });
        } else {
            addFaq({
                faq: faq,
            });
        }
    };
    return (
        <VStack w="100%">
            {[...Array(inputsLength)].map((_, index) => {
                return (
                    <VStack
                        p={6}
                        gap={4}
                        alignItems="start"
                        bgColor="rgba(255, 255, 255, 1)"
                        borderRadius="8px"
                        width="100%"
                    >
                        <Button onClick={() => handelDelete(index)} alignSelf="end">
                            <IoCloseCircle fontSize="30px" color="gray.200" />
                        </Button>
                        <HStack width="100%" gap={2}>
                            <VStack alignItems="start" width="50%">
                                <Text fontSize="16px" fontWeight="5oo" color="rgba(129, 129, 129, 1)">
                                    English Question
                                </Text>
                                <Input
                                    placeholder={faqs?.[index]?.question_en}
                                    onChange={(e) => {
                                        setFaq((prev) => ({ ...prev, question_en: e.target.value }));
                                    }}
                                    _placeholder={{
                                        color: 'black',
                                    }}
                                />
                            </VStack>
                            <VStack alignItems="start" width="50%">
                                <Text fontSize="16px" fontWeight="5oo" color="rgba(129, 129, 129, 1)">
                                    Arabic Question
                                </Text>
                                <Input
                                    placeholder={faqs?.[index]?.question_ar}
                                    onChange={(e) => {
                                        setFaq((prev) => ({ ...prev, question_ar: e.target.value }));
                                    }}
                                    _placeholder={{
                                        color: 'black',
                                    }}
                                />
                            </VStack>
                        </HStack>
                        <HStack width="100%" gap={2}>
                            <VStack alignItems="start" width="50%">
                                <Text fontSize="16px" fontWeight="5oo" color="rgba(129, 129, 129, 1)">
                                    English Answer
                                </Text>
                                <Textarea
                                    placeholder={faqs?.[index]?.answer}
                                    onChange={(e) => {
                                        setFaq((prev) => ({ ...prev, answer_en: e.target.value }));
                                    }}
                                    _placeholder={{
                                        color: 'black',
                                    }}
                                />
                            </VStack>
                            <VStack alignItems="start" width="50%">
                                <Text fontSize="16px" fontWeight="5oo" color="rgba(129, 129, 129, 1)">
                                    Arabic Answer
                                </Text>
                                <Textarea
                                    placeholder={faqs?.[index]?.answer_ar}
                                    onChange={(e) => {
                                        setFaq((prev) => ({ ...prev, answer_ar: e.target.value }));
                                    }}
                                    _placeholder={{
                                        color: 'black',
                                    }}
                                />
                            </VStack>
                        </HStack>

                        <Button
                            width={{ base: '100%', md: '25%' }}
                            variant="solid"
                            isLoading={isLoading}
                            onClick={() => {
                                handelSave(index);
                            }}
                        >
                            save
                        </Button>
                    </VStack>
                );
            })}

            <HStack w="100%">
                <Button
                    my={2}
                    variant="outline"
                    onClick={() => {
                        setInputsLength((s) => s + 1);
                    }}
                >
                    Add new question
                </Button>
            </HStack>
        </VStack>
    );
};

export default FaqForm;
