import { HStack, Stack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink, useParams } from 'react-router-dom';
import { useFetchStoresQuery } from 'services/apis/storesApi';
import { useFetchUserQuery } from 'services/apis/users';
import UserFormTemplate from 'templates/admins/form';
import CreateCategoryTemplate from 'templates/categories/create';

const UserForm = ({ setTitle, user }) => {
    const { id, user_type } = useParams();
    const { data, error, isLoading } = useFetchUserQuery({ id, user_type });

    useEffect(() => {
        const title = (
            <HStack>
                <NavLink to="/admins">Admins</NavLink>
                <IoIosArrowForward />
                <Text>{id ? `Edit Admin` : 'Add new category'}</Text>
            </HStack>
        );
        setTitle(title);
    }, []);
    return <UserFormTemplate data={data?.data?.user} user={user} />;
};

export default UserForm;
