import { Flex } from '@chakra-ui/react';
import CEditableInput from 'common/forms/components/CEditableInput';
import CUpload from 'common/forms/components/CUpload';
import React, { useEffect, useState } from 'react';
import { useUpdateCountryMutation } from 'services/apis/countriesApi';

const UpdateForm = ({ data }) => {
    const [updateCountry] = useUpdateCountryMutation();
    const [newLogo, setNewLogo] = useState(null);

    const handelUpdate = (val, name) => {
        const body = {
            country: { ...data, [`${name}`]: val },
        };

        updateCountry({ id: data?.id, body });
    };
    useEffect(() => {
        if (newLogo) handelUpdate(newLogo, 'logo');
    }, [newLogo]);
    return (
        <Flex
            gap={6}
            width="100%"
            flexWrap="wrap"
            flexDirection="column"
            bg="white"
            p="20px"
            borderRadius="10px"
            mt={6}
        >
            <CUpload changeAction={(value) => setNewLogo(value)} label="Edit photo" value={data?.logo} type="edit" />
            <CEditableInput
                defaultValue={data?.name_en}
                name="name_en"
                handelSubmit={handelUpdate}
                label="Country name in English"
            />
            <CEditableInput
                defaultValue={data?.name_ar}
                name="name_ar"
                handelSubmit={handelUpdate}
                label="Country name in Arabic"
            />
            <CEditableInput
                defaultValue={data?.lookup_key}
                name="lookup_key"
                handelSubmit={handelUpdate}
                label="lookup Key"
            />
        </Flex>
    );
};

export default UpdateForm;
