import {
    Avatar,
    Box,
    Button,
    Divider,
    HStack,
    Heading,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useFetchCountriesQuery } from 'services/apis/countriesApi';

const Header = ({ title, user }) => {
    const navigate = useNavigate();

    const { data: countries } = useFetchCountriesQuery();
    const countriesList = countries?.data?.countries;

    const [selectedCountry, setSelectedCountery] = useState();
    useEffect(() => {
        const findCountry = countriesList?.find((data) => data?.lookup_key == localStorage.getItem('country'));
        setSelectedCountery(findCountry);
    }, [countriesList]);

    return (
        <Box width="100%" height="106px">
            <Stack
                position="fixed"
                zIndex="10"
                flexDirection="row"
                width={{ base: '90%', md: '80%' }}
                height="106px"
                bg="white"
                alignItems="center"
                justifyContent="space-between"
                px={6}
            >
                <Heading as="h5" fontSize="17px" color="rgba(0, 1, 5, 0.7)">
                    {title}
                </Heading>
                <HStack alignItems="center" gap={4}>
                    <Stack
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                        cursor="pointer"
                        onClick={() => navigate('/profile')}
                    >
                        <Avatar size="sm" name={user?.name} src={user?.avatar} />{' '}
                        <Text fontSize="14px" textTransform="capitalize">
                            {user?.name}
                        </Text>
                    </Stack>
                    <Menu>
                        <MenuButton as={Button} rightIcon={<IoMdArrowDropdown />}>
                            <Avatar
                                size="sm"
                                name="Kent Dodds"
                                src={selectedCountry?.logo || 'https://bit.ly/kent-c-dodds'}
                            />
                        </MenuButton>
                        <MenuList>
                            {countriesList?.map((data) => {
                                return (
                                    <MenuItem
                                        onClick={() => {
                                            localStorage.setItem('country', data?.lookup_key);
                                            window.location.reload();
                                        }}
                                        minH="48px"
                                        display="flex"
                                        flexDirection="column"
                                        gap={2}
                                    >
                                        <Stack
                                            width="100%"
                                            flexDirection="row"
                                            gap={2}
                                            justifyContent="start"
                                            alignItems="center"
                                        >
                                            <Avatar size="sm" name="Kent Dodds" src={data?.logo} />
                                            <Text fontSize="13px" fontWeight="500" color="#818181">
                                                {data?.name}
                                            </Text>
                                        </Stack>

                                        <Divider />
                                    </MenuItem>
                                );
                            })}
                        </MenuList>
                    </Menu>
                </HStack>
            </Stack>
        </Box>
    );
};

export default Header;
