import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    createCoupons: false,
    addCategoryStores: false,
    deactivateProfile: false,
    changePassword: false,
};

const modelSlice = createSlice({
    name: 'models',
    initialState,
    reducers: {
        openCreateCoupons(state) {
            state.createCoupons = !state.createCoupons;
        },
        openAddCategoryStores(state) {
            state.addCategoryStores = !state.addCategoryStores;
        },
        openDeactivateProfile(state, action) {
            state.deactivateProfile = !state.deactivateProfile;
        },
        openChangePasswordModel(state, action) {
            state.changePassword = !state.changePassword;
        },
    },
});

export const { openCreateCoupons, openAddCategoryStores, openDeactivateProfile, openChangePasswordModel } =
    modelSlice.actions;
export default modelSlice;
