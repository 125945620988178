import { HStack, Spinner, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink, useParams } from 'react-router-dom';
import { useFetchCategoryQuery } from 'services/apis/categoryApi';
import { useFetchStoresQuery } from 'services/apis/storesApi';
import UpdateCategoryTemplate from 'templates/categories/update';

const UpdateCategory = ({ setTitle }) => {
    const { id } = useParams();
    const { data, error, isLoading } = useFetchCategoryQuery(id, {
        skip: !id,
    });
    const [filterObject, setFilterObject] = useState({
        category_id: id,
        keyword: '',
        page_size: 200,
    });
    const { data: stores } = useFetchStoresQuery(filterObject, {
        skip: !id,
    });
    const storesList = stores?.data?.stores;
    const storesCount = Math.ceil(stores?.extra?.total_count / 10) || 1;

    useEffect(() => {
        const title = (
            <HStack>
                <NavLink to="/categories">Categories</NavLink>
                <IoIosArrowForward />
                <Text>Edit </Text>
            </HStack>
        );
        setTitle(title);
    }, []);
    return isLoading ? (
        <Spinner />
    ) : (
        <UpdateCategoryTemplate
            storesCount={storesCount}
            setFilterObject={setFilterObject}
            data={data?.data?.category}
            storesList={storesList}
        />
    );
};

export default UpdateCategory;
