import { Box, Stack, Textarea } from '@chakra-ui/react';
import React from 'react';
import CFormErrors from './CFormErrors';
import CFormLabels from './CFormLabels';

const CTextArea = ({ label, placeholder, type = 'text', name, formik, colSpan }) => {
    const errorsMsg = formik.errors[name];
    const value = formik.values[name];
    //console.log(colSpan);
    return (
        <Stack width="100%">
            <CFormLabels label={label} />

            <Textarea
                width="100%"
                placeholder={placeholder}
                type={type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={value}
                name={name}
                multiline
                rows={colSpan}
            />

            <CFormErrors errorsMsg={errorsMsg} />
        </Stack>
    );
};

export default CTextArea;
