import { Editable, EditableInput, EditablePreview, Input, Stack } from '@chakra-ui/react';
import CFormLabels from './CFormLabels';

const CEditableInput = ({ defaultValue, handelSubmit, name, label, width = '50%' }) => {
    /* Here's a custom control */
    //console.log(defaultValue);
    return (
        <Stack width={{ base: '100%', md: width }}>
            <CFormLabels label={label} />
            <Editable
                display="flex"
                flexDir="row"
                textAlign="center"
                defaultValue={defaultValue}
                fontSize="2xl"
                isPreviewFocusable={true}
                width="100%"
                onSubmit={(val) => handelSubmit(val, name)}
            >
                <EditablePreview
                    textAlign="start"
                    border={'1px solid rgba(217, 217, 217, 1)'}
                    borderRadius="4px"
                    width="100%"
                    px={3}
                    fontSize="18px"
                    color="rgba(51, 49, 50, 1)"
                />
                {/* Here is the custom input */}
                <Input as={EditableInput} textAlign="start" />
            </Editable>
        </Stack>
    );
};

export default CEditableInput;
