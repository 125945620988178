import { HStack, Stack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import { useFetchStoresQuery } from 'services/apis/storesApi';
import CreateCategoryTemplate from 'templates/categories/create';

const CreateCategory = ({ setTitle }) => {
    const { data: stores } = useFetchStoresQuery({ page_size: 200 });
    const storesList = stores?.data?.stores;
    useEffect(() => {
        const title = (
            <HStack>
                <NavLink to="/categories">Categories</NavLink>
                <IoIosArrowForward />
                <Text>Add new category</Text>
            </HStack>
        );
        setTitle(title);
    }, []);
    return <CreateCategoryTemplate storesList={storesList} />;
};

export default CreateCategory;
