import { Menu, MenuButton, MenuItem, MenuList, Stack, Text, IconButton } from '@chakra-ui/react';
import React from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { BiChevronDown } from 'react-icons/bi';

const Footer = ({ pageIndex, totalPage, setPageNumber, pagesNumber = 1, pageNumber }) => {
    //console.log(pagesNumber, pageIndex, totalPage, pageNumber);
    return (
        <Stack direction={{ xs: 'column', md: 'row' }} bg="transparent" alignItems="center" justifyContent="end" p={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Menu>
                    <MenuButton
                        p={0}
                        w="50px"
                        transition="all 0.2s"
                        borderRadius="md"
                        borderWidth="1px"
                        bg="#E9E9E9"
                        _hover={{ bg: 'transparent' }}
                        _expanded={{ bg: 'transparent' }}
                        _focus={{ boxShadow: 'outline' }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            '& span': {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            },
                        }}
                    >
                        <Text mx="5px">{pageNumber == 0 ? 'All' : pageNumber}</Text> <BiChevronDown />
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => setPageNumber(0)}>All</MenuItem>
                        {[...Array(pagesNumber)]?.map((_, index) => {
                            return (
                                <MenuItem value={index + 1} key={index} onClick={() => setPageNumber(index + 1)}>
                                    {index + 1}
                                </MenuItem>
                            );
                        })}
                    </MenuList>
                </Menu>
                <Stack direction="row" alignItems="center" gap={1}>
                    <Text color="#666666">of {pagesNumber} pages</Text>
                    <IconButton
                        aria-label="previousPage"
                        onClick={() => {
                            setPageNumber((s) => (s === 1 ? 1 : s - 1));
                        }}
                        isDisabled={pagesNumber === 1 || pageNumber === 1}
                    >
                        <IoIosArrowBack />
                    </IconButton>
                    <span>{pageNumber == 0 ? 'All' : pageNumber}</span>
                    <IconButton
                        aria-label="nextPage"
                        onClick={() => {
                            setPageNumber((s) => s + 1);
                        }}
                        isDisabled={pagesNumber === pageNumber}
                    >
                        <IoIosArrowForward />
                    </IconButton>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Footer;
