import React, { useEffect, useState } from 'react';
import MainTable from 'common/table/index';
import Actions from 'common/table/actions';
import { Box, Image, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRemoveStoreMutation, useUpdateStoreMutation } from 'services/apis/storesApi';
import { BsImageFill } from 'react-icons/bs';
import dayjs from 'dayjs';

const StoresTemplate = ({ list, setFilterObject, count }) => {
    //console.log(list);
    const navigate = useNavigate();
    const [removeStore, removeStorResults] = useRemoveStoreMutation();
    const [updateStore] = useUpdateStoreMutation();

    const actionsList = (item) => [
        {
            type: 'active',
            func: () => {
                updateStore({
                    id: item?.id,
                    body: { store: { ...item, is_visible: !item?.is_visible } },
                });
            },
        },
        {
            type: 'edit',
            func: () => {
                navigate(`/stores/update-store/${item?.id}`);
            },
        },
        {
            type: 'delete',
            func: () => {
                removeStore(item?.id);
            },
        },
    ];
    const [checked, setChecked] = useState([false]);
    const [perPage, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [keyword, setKeyword] = useState('');

    const mappedList = list?.map((item) => {
        return {
            ...item,
            date: <Text color="main_font">{dayjs(item?.created_at).format('DD/MM/YYYY')}</Text>,

            image: item?.logo ? (
                <Image src={item?.logo} width="35px" height="25px" />
            ) : (
                <BsImageFill width="35px" height="25px" />
            ),
            action: <Actions list={actionsList(item)} item={item} />,
        };
    });
    const createBtnFun = (val) => {
        navigate('/stores/add-new-store');
    };

    useEffect(() => {
        setFilterObject((prevState) => {
            return {
                ...prevState,
                keyword: keyword,
                page_number: pageNumber,
                page_size: pageNumber == 0 ? 200 : 10,
            };
        });
    }, [pageNumber, keyword]);

    return (
        <Box px={6} w="100%" pt={4}>
            <MainTable
                Data={mappedList || [{}]}
                Columns={[
                    {
                        Header: 'Image',
                        accessor: 'image',
                    },
                    {
                        Header: 'Name',
                        accessor: 'name',
                        sort: true,
                    },
                    {
                        Header: 'Coupons',
                        accessor: 'promocodes_count',
                        sort: true,
                    },
                    {
                        Header: 'Products',
                        accessor: 'products_count',
                        sort: true,
                    },
                    {
                        Header: 'created dates',
                        accessor: 'date',
                        sort: true,
                    },
                    {
                        Header: 'Action',
                        accessor: 'action',
                    },
                ]}
                isChecked
                isSearch
                isFilter={false}
                searchFun={(val) => {
                    setPageNumber(1);
                    setKeyword(val);
                }}
                selectedData={() => {}}
                currentpage={1}
                setPage={() => {}}
                perPage={perPage}
                totalPage={1}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                checked={checked}
                setChecked={setChecked}
                setPerPage={setPerPage}
                createBtnTitle="Add a New Store"
                createBtnFun={createBtnFun}
                placeholder="Search Stores..."
                filterList={<></>}
                pagesNumber={count == 0 ? 1 : count}
            />
        </Box>
    );
};

export default StoresTemplate;
