import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
    colors: {
        main_color: '#FF9700',
        main_dark_color: '#000105',
        main_font: '#818181',
        border_color: '#D9D9D9',
        brand: {
            100: '#f7fafc',
            // ...
            900: '#1a202c',
        },
    },
    components: {
        Button: {
            baseStyle: {
                fontWeight: 'bold', // Normally, it is "semibold"
            },
            variants: {
                solid: {
                    bg: '#FF9700',
                    color: '#FFFFFF',
                    _hover: {
                        bg: '#FF9700',
                    },
                },
                outline: {
                    border: '2px solid #FF9700',
                    color: '#FF9700',
                    bg: 'transparent',
                },
            },
            defaultProps: {
                size: 'md', // default is md
                variant: 'sm', // default is solid
                colorScheme: 'green', // default is gray
            },
        },
        Input: {
            baseStyle: {
                field: {
                    width: '100%',
                    background: 'white',
                    objectFit: 'contain',
                    borderRadius: '4px',
                    border: '1px solid #D9D9D9',
                },
            },
        },
        Textarea: {
            baseStyle: {
                field: {
                    width: '100%',
                    background: 'white',
                    objectFit: 'contain',
                    borderRadius: '4px',
                    border: '1px solid #D9D9D9',
                },
            },
        },
        Text: {
            baseStyle: {
                color: 'rgba(51, 49, 50, 1)',
            },
            variants: {
                labels: {
                    color: '#818181',
                    fontWeight: '500',
                    fontSize: '16px',
                },
            },
        },
    },
});
