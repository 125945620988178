import React from 'react';
import FormIndex from 'common/forms';
import { useFormik } from 'formik';
import { Button } from '@chakra-ui/react';
import { structure } from 'components/organisms/products/forms/add-form';
import { useAddProductMutation } from 'services/apis/productsApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const CreateProductTemplate = ({ categoriesList, storesList, currenciesList }) => {
    const [addProduct, { isLoading }] = useAddProductMutation();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: { is_visible: true, is_top_deal: true },

        onSubmit: (values) => {
            const category_id = values?.category_id?.value;
            const currency_id = values?.currency_id?.value;
            const store_id = values?.store_id?.value;

            addProduct({ product: { ...values, category_id, currency_id, store_id } }).then((res) => {
                if (res?.error) toast.error(res?.error?.data?.message);
                else navigate('/products');
            });
        },
    });

    return (
        <FormIndex formStructures={structure(categoriesList, storesList, currenciesList)} formik={formik}>
            <Button
                isLoading={isLoading}
                type="submit"
                variant="solid"
                width={{ base: '100%', md: '25%' }}
                alignSelf="self-start"
            >
                Save
            </Button>
        </FormIndex>
    );
};

export default CreateProductTemplate;
