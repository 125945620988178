import { HStack, Spinner, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink, useParams } from 'react-router-dom';
import { useFetchCtegoriesQuery } from 'services/apis/categoryApi';
import { useFetchCouponsQuery } from 'services/apis/couponsApi';
import { useFetchProductsQuery } from 'services/apis/productsApi';
import { useFetchStoreQuery } from 'services/apis/storesApi';
import UpdateStoreTemplate from 'templates/stores/update';

const UpdateStore = ({ setTitle }) => {
    const [productFilter, setProductFilter] = useState('');
    const [couponFilter, setCouponFilter] = useState('');

    const { id } = useParams();
    const { data, error, isLoading } = useFetchStoreQuery(id);

    const { data: categories } = useFetchCtegoriesQuery({ page_size: 200 });
    const categoriesList = categories?.data?.categories;

    const { data: products } = useFetchProductsQuery({ store_id: id, ...productFilter });
    const productsList = products?.data?.products;
    const productsCount = Math.ceil(products?.extra?.total_count / 10);

    const { data: coupons } = useFetchCouponsQuery({ store_id: id, ...couponFilter });
    const couponsList = coupons?.data?.promocodes;
    const couponsCount = Math.ceil(coupons?.extra?.total_count / 10);

    useEffect(() => {
        const title = (
            <HStack>
                <NavLink to="/stores">Stores</NavLink>
                <IoIosArrowForward />
                <Text>Edit </Text>
            </HStack>
        );
        setTitle(title);
    }, []);
    return isLoading ? (
        <Spinner />
    ) : (
        <UpdateStoreTemplate
            data={data?.data?.store}
            categoriesList={categoriesList}
            productsList={productsList}
            couponsList={couponsList}
            setProductFilter={setProductFilter}
            setCouponFilter={setCouponFilter}
            productsCount={productsCount}
            couponsCount={couponsCount}
        />
    );
};

export default UpdateStore;
