import React, { useEffect, useState } from 'react';
import MainTable from 'common/table/index';
import Actions from 'common/table/actions';
import { Box, Button, Divider, HStack, Image, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRemoveProductMutation, useUpdateProductMutation } from 'services/apis/productsApi';
import dayjs from 'dayjs';
import { BsImageFill } from 'react-icons/bs';

const ProductsTemplate = ({ list, setFilterObject, count }) => {
    const navigate = useNavigate();
    const [removeProduct] = useRemoveProductMutation();
    const [updateProduct] = useUpdateProductMutation();

    const actionsList = (item) => [
        {
            type: 'active',
            func: () => {
                updateProduct({
                    id: item?.id,
                    body: { product: { ...item, is_visible: !item?.is_visible } },
                });
            },
        },
        {
            type: 'edit',
            func: () => {
                navigate(`/products/update-product/${item?.id}`);
            },
        },
        {
            type: 'delete',
            func: () => {
                removeProduct(item?.id);
            },
        },
    ];
    const [checked, setChecked] = useState([false]);
    const [perPage, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [keyword, setKeyword] = useState('');
    const [isVisible, setIsVisible] = useState(null);

    useEffect(() => {
        setFilterObject((prevState) => {
            return {
                ...prevState,
                keyword: keyword,
                page_number: pageNumber,
                page_size: pageNumber == 0 ? 200 : 10,
            };
        });
    }, [pageNumber, keyword]);

    const mappedList = list?.map((item) => {
        return {
            ...item,
            image: item?.image ? (
                <Image src={item?.image} width="35px" height="25px" />
            ) : (
                <BsImageFill width="35px" height="25px" />
            ),
            priceBefore: `${item?.price} ${item?.currency?.iso_code}`,
            priceAfter: `${item?.price_after_discount} ${item?.currency?.iso_code}`,
            inhome: item?.is_visible ? <Text color="#3BA733">Yes</Text> : <Text color="#EB0000">No</Text>,
            date: <Text color="main_font">{dayjs(item?.created_at).format('DD/MM/YYYY')}</Text>,
            action: <Actions list={actionsList(item)} item={item} />,
        };
    });
    const createBtnFun = (val) => {
        navigate('/products/add-new-product');
    };
    const filterList = () => {
        return (
            <>
                <RadioGroup value={isVisible} onChange={(e) => setIsVisible(e)} p={2}>
                    <Text fontSize="14px" fontWeight="600">
                        In Home
                    </Text>
                    <Divider my={4} />
                    <Stack gap={4}>
                        <Radio value="1">Yes</Radio>
                        <Radio value="0">No</Radio>
                    </Stack>
                    <HStack justifyContent="end">
                        <Button
                            color="#BABCBC"
                            onClick={() => {
                                setIsVisible('-1');
                                setPageNumber(1);
                                setFilterObject((prevState) => {
                                    return {
                                        page_number: 1,
                                    };
                                });
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            color="main_color"
                            onClick={() => {
                                setPageNumber(1);
                                setFilterObject((prevState) => {
                                    return {
                                        ...prevState,
                                        is_visible: isVisible == 0 ? false : true,
                                        page_number: 1,
                                    };
                                });
                            }}
                        >
                            Apply
                        </Button>
                    </HStack>
                </RadioGroup>
            </>
        );
    };
    return (
        <Box px={6} w="100%" pt={4}>
            <MainTable
                Data={mappedList || [{}]}
                Columns={[
                    {
                        Header: 'Name',
                        accessor: 'name',
                    },
                    {
                        Header: 'Image',
                        accessor: 'image',
                    },
                    {
                        Header: 'Code',
                        accessor: 'code',
                    },
                    {
                        Header: 'Category',
                        accessor: 'category.name',
                    },
                    {
                        Header: 'Store',
                        accessor: 'store.name',
                        sort: true,
                    },
                    {
                        Header: 'Old price',
                        accessor: 'priceBefore',
                        sort: true,
                    },
                    {
                        Header: 'New Price',
                        accessor: 'priceAfter',
                        sort: true,
                    },
                    {
                        Header: 'In home',
                        accessor: 'inhome',
                    },
                    {
                        Header: 'created dates',
                        accessor: 'date',
                        sort: true,
                    },
                    {
                        Header: 'Action',
                        accessor: 'action',
                    },
                ]}
                isChecked
                isSearch
                isFilter
                searchFun={(val) => {
                    setPageNumber(1);
                    setKeyword(val);
                }}
                selectedData={() => {}}
                currentpage={1}
                setPage={() => {}}
                perPage={perPage}
                totalPage={1}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                checked={checked}
                setChecked={setChecked}
                setPerPage={setPerPage}
                createBtnTitle="Add a New Product"
                createBtnFun={createBtnFun}
                placeholder="Search Products..."
                filterList={filterList()}
                pagesNumber={count == 0 ? 1 : count}
            />
        </Box>
    );
};

export default ProductsTemplate;
