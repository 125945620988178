import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-headers';

const currenciesApi = createApi({
    reducerPath: 'currencies',
    baseQuery: baseQuery,
    endpoints(builder) {
        return {
            removeCurrency: builder.mutation({
                query: (id) => {
                    return {
                        url: `/lookups/currencies/${id}`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: ['Currency'],
            }),
            addCurrency: builder.mutation({
                query: (body) => {
                    return {
                        url: '/lookups/currencies',
                        method: 'POST',
                        body: body,
                    };
                },
                invalidatesTags: ['Currency'],
            }),
            updateCurrency: builder.mutation({
                query: (data) => {
                    //console.log(data);
                    return {
                        url: `/lookups/currencies/${data?.id}`,
                        method: 'PUT',
                        body: data?.body,
                    };
                },
                invalidatesTags: ['Currency'],
            }),
            fetchCurrencies: builder.query({
                query: (filterObj) => {
                    return {
                        url: '/lookups/currencies',
                        method: 'GET',
                        params: filterObj,
                    };
                },
                providesTags: ['Currency'],
            }),
            fetchCurrency: builder.query({
                query: (id) => `/lookups/currencies/${id}`,
                providesTags: (result, error, id) => [{ type: 'Currency', id }],
            }),
        };
    },
});

export const {
    useFetchCurrenciesQuery,
    useAddCurrencyMutation,
    useUpdateCurrencyMutation,
    useFetchCurrencyQuery,
    useRemoveCurrencyMutation,
} = currenciesApi;
export { currenciesApi };
