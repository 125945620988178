import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import CInput from './components/CInput';
import CPasswordInput from './components/CTextArea';
import CTextArea from './components/CTextArea';
import SelectOptions from './components/CSelect';
import CCheckbox from './components/CCheckbox';
import CUpload from './components/CUpload';
import CPhoneInput from './components/CPhoneInput';
// import CUpload from './components/CUpload';

const FormIndex = ({ formStructures, formik, children, width }) => {
    const renderInputs = (data, index) => {
        const generalProps = {
            ...data,
            formik: formik,
            colSpan: data?.colSpan,
            index: index,
        };
        if (data.kind === 'input') return <CInput {...generalProps} />;
        if (data.kind === 'passwordInput') return <CPasswordInput {...generalProps} />;
        if (data.kind === 'textArea') return <CTextArea {...generalProps} />;
        if (data.kind === 'select')
            return (
                <SelectOptions
                    {...generalProps}
                    changeAction={(value) => formik.setValues({ ...formik?.values, [data?.name]: value })}
                />
            );
        if (data.kind === 'phone')
            return (
                <CPhoneInput
                    {...generalProps}
                    changeAction={(value, country, e, formattedValue) => {
                        formik.setValues({
                            ...formik?.values,
                            phone_number: value,
                            country_code: `+${country?.dialCode}`,
                        });
                    }}
                />
            );
        if (data.kind === 'checkbox')
            return (
                <CCheckbox
                    {...generalProps}
                    changeAction={(value) => formik.setValues({ ...formik?.values, [data?.name]: value })}
                />
            );
        if (data.kind === 'upload')
            return (
                <CUpload
                    {...generalProps}
                    changeAction={(value) => formik.setValues({ ...formik?.values, [data?.name]: value })}
                />
            );
    };

    return (
        <Box as="form" onSubmit={formik?.handleSubmit} sx={{ width: width || '90%', mt: 6 }}>
            <Flex gap={6} width="100%" flexWrap="wrap" flexDirection="column" bg="white" p="20px" borderRadius="10px">
                {formStructures?.map((data, index) => {
                    return (
                        <Flex gap={3} width={data?.width || '100%'} flexWrap="wrap" flexDirection="row">
                            {renderInputs(data, index)}
                        </Flex>
                    );
                })}
                {children}
            </Flex>
        </Box>
    );
};

export default FormIndex;
