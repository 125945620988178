import React from 'react';
import { VStack } from '@chakra-ui/react';
import UpdateForm from 'components/organisms/products/forms/update-form';
import ProductPriceData from 'components/organisms/products/components/product-price-data';

const UpdateProductTemplate = ({ data }) => {
    return (
        <VStack w="95%" gap={6} mb={6}>
            <UpdateForm data={data} />
            <ProductPriceData data={data} />
        </VStack>
    );
};

export default UpdateProductTemplate;
