import React, { useState } from 'react';
import MainTable from 'common/table/index';
import Actions from 'common/table/actions';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const NotificationsTemplate = ({ list, count }) => {
    const navigate = useNavigate();

    const [checked, setChecked] = useState([false]);
    const [perPage, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);

    const mappedList = list?.map((item) => {
        return {
            ...item,
        };
    });
    const createBtnFun = (val) => {
        navigate('/notifications/add-new-notification');
    };

    return (
        <Box px={6} w="100%" pt={4}>
            <MainTable
                Data={mappedList}
                Columns={[
                    {
                        Header: 'Title',
                        accessor: 'title',
                    },
                ]}
                isChecked
                isSearch={false}
                isFilter={false}
                searchFun={() => {}}
                selectedData={() => {}}
                currentpage={1}
                setPage={() => {}}
                perPage={perPage}
                totalPage={1}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                checked={checked}
                setChecked={setChecked}
                setPerPage={setPerPage}
                createBtnTitle="Add a New Notification"
                createBtnFun={createBtnFun}
                placeholder="Search Notifications..."
                filterList={<></>}
                pagesNumber={count == 0 ? 1 : count}
            />
        </Box>
    );
};

export default NotificationsTemplate;
