import React, { useEffect } from 'react';
import { useFetchContactsQuery } from 'services/apis/documents';
import ContactusTemplate from 'templates/documents/contactus';

const Contactus = ({ setTitle }) => {
    const { data } = useFetchContactsQuery({});
    const list = data?.data?.contacts;
    useEffect(() => {
        setTitle('Contact us');
    }, []);
    return <ContactusTemplate list={list} />;
};

export default Contactus;
