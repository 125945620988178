import { Box, Button, Image, VStack } from '@chakra-ui/react';
import FormIndex from 'common/forms';
import { structure } from 'components/organisms/auth/login/form/structure';
import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLoginMutation } from 'services/apis/authApi';

const LoginTemplate = () => {
    const [login] = useLoginMutation();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {},
        onSubmit: (values) => {
            login({
                user: { ...values },
                device: {
                    device_type: 'web', //"android" , "ios" , "web", "unknown"
                    fcm_token: 'uuid',
                },
            }).then((res) => {
                if (res?.error) toast.error(res?.error?.data?.message);
                else {
                    localStorage.setItem('token', res?.data?.data?.extra?.access_token);
                    localStorage.setItem('id', res?.data?.data?.user?.id);
                    localStorage.setItem('privilege', res?.data?.data?.user?.privilege);

                    window.location.replace('/');
                }
            });
        },
    });
    return (
        <VStack bgColor="#E9E9E9" minH="100vh" justifyContent="center" alignItems="center" gap="60px">
            <Image src={require('assets/images/logo.png')} width="230px" height="75px" alignSelf="center" />
            <Box
                width={{ base: '100%', md: '35%' }}
                bgColor="white"
                alignSelf="center"
                boxShadow="0px 2px 4px 0px #00000040"
                borderRadius="16px"
            >
                <FormIndex formStructures={structure()} formik={formik} width="100%">
                    <Button type="submit" variant="solid" width={{ base: '100%', md: '25%' }} alignSelf="center">
                        Log in
                    </Button>
                </FormIndex>
            </Box>
        </VStack>
    );
};

export default LoginTemplate;
