import { HStack, Stack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import { useFetchCtegoriesQuery } from 'services/apis/categoryApi';
import { useFetchStoresQuery } from 'services/apis/storesApi';
import CreateCouponTemplate from 'templates/coupons/create';

const CreateCoupon = ({ setTitle }) => {
    const { data, error, isLoading } = useFetchStoresQuery({ page_size: 200 });
    const stores = data?.data?.stores;
    useEffect(() => {
        const title = (
            <HStack>
                <NavLink to="/Coupons">Coupons</NavLink>
                <IoIosArrowForward />
                <Text>Add new coupon</Text>
            </HStack>
        );
        setTitle(title);
    }, []);
    return <CreateCouponTemplate stores={stores} />;
};
export default CreateCoupon;
