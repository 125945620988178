import { Box, Stack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

const TabsLayout = () => {
    const tabsList = [
        { label: 'FAQ', path: '/documents/faq' },
        { label: 'Privacy Policy', path: '/documents/privacy-policy' },
        { label: 'Contact Us', path: '/documents/contact-us' },
        { label: 'Terms & Conditions', path: '/documents/terms-conditions' },
        { label: 'Pixel Data', path: '/documents/pixel' },
    ];
    const { pathname } = useLocation();
    return (
        <VStack width="90%" my={4}>
            <Stack
                my={4}
                gap={0}
                flexDirection="row"
                flexWrap="wrap"
                // width="100%"
                border="1px solid"
                borderColor="main_color"
            >
                {tabsList?.map((data, index) => {
                    return (
                        <Box
                            key={index}
                            color="main_color"
                            borderLeft="1px solid"
                            borderTop={{ base: '1px solid', md: '0' }}
                            bgColor={pathname == data?.path ? 'main_color' : 'transparent'}
                            padding={2}
                            minW={{ base: '100%', md: '200px' }}
                        >
                            <Link to={data?.path} style={{ width: '100%' }}>
                                <Text
                                    fontWeight="700"
                                    color={pathname != data?.path ? 'main_color' : 'white'}
                                    textAlign="center"
                                >
                                    {data?.label}
                                </Text>
                            </Link>
                        </Box>
                    );
                })}
            </Stack>
            <Outlet />
        </VStack>
    );
};

export default TabsLayout;
