import { HStack, Stack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import CreateCurrencyTemplate from 'templates/currencies/create';

const CreateCurrency = ({ setTitle }) => {
    useEffect(() => {
        const title = (
            <HStack>
                <NavLink to="/countries">Currencies</NavLink>
                <IoIosArrowForward />
                <Text>Add new Currency</Text>
            </HStack>
        );
        setTitle(title);
    }, []);
    return <CreateCurrencyTemplate />;
};

export default CreateCurrency;
