import React, { useEffect, useState } from 'react';
import MainTable from 'common/table/index';
import Actions from 'common/table/actions';
import { Box, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { BsImageFill } from 'react-icons/bs';

const UsersTemplate = ({ list, setFilterObject, count }) => {
    const navigate = useNavigate();
    const actionsList = (item) => [
        {
            type: 'details',
            func: () => {
                navigate(`/users/${item?.id}`);
            },
        },
    ];
    const [checked, setChecked] = useState([false]);
    const [perPage, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);

    const mappedList = list?.map((item) => {
        return {
            ...item,
            avatar: item?.avatar ? (
                <Image src={item?.avatar} width="35px" height="25px" />
            ) : (
                <BsImageFill width="35px" height="25px" />
            ),
        };
    });
    useEffect(() => {
        setFilterObject((prevState) => {
            return {
                ...prevState,
                page_size: pageNumber == 0 ? 200 : 10,
                page_number: pageNumber,
            };
        });
    }, [pageNumber]);
    return (
        <Box px={6} w="100%" pt={4}>
            <MainTable
                Data={mappedList}
                Columns={[
                    {
                        Header: 'Name',
                        accessor: 'name',
                    },
                    {
                        Header: 'Image',
                        accessor: 'avatar',
                    },

                    {
                        Header: 'Email',
                        accessor: 'email',
                    },
                    // {
                    //     Header: 'No of copied coupons',
                    //     accessor: 'no_of_copied',
                    // },
                    // {
                    //     Header: 'Regestration  date ',
                    //     accessor: 'date',
                    // },
                    // {
                    //     Header: 'Action',
                    //     accessor: 'action',
                    // },
                ]}
                isChecked
                isSearch
                isFilter={false}
                searchFun={(val) => {
                    setFilterObject((s) => {
                        return { ...s, keyword: val };
                    });
                }}
                selectedData={() => {}}
                currentpage={1}
                setPage={() => {}}
                perPage={perPage}
                totalPage={1}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                checked={checked}
                setChecked={setChecked}
                setPerPage={setPerPage}
                createBtnTitle="Add a New User"
                createBtnFun={null}
                placeholder="Search Users..."
                filterList={<></>}
                pagesNumber={count == 0 ? 1 : count}
            />
        </Box>
    );
};

export default UsersTemplate;
