import { HStack, Stack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import CreateCategoryTemplate from 'templates/categories/create';
import CreateNotificationTemplate from 'templates/notifications/create';

const CreateNotification = ({ setTitle }) => {
    useEffect(() => {
        const title = (
            <HStack>
                <NavLink to="/notifications">Notifications</NavLink>
                <IoIosArrowForward />
                <Text>Add new Notification</Text>
            </HStack>
        );
        setTitle(title);
    }, []);
    return <CreateNotificationTemplate />;
};

export default CreateNotification;
