import { Button, Textarea, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useUpdateDocumentsMutation } from 'services/apis/documents';

const TermaAndConditionsTmplate = ({ document }) => {
    const [contact, setContact] = useState(null);
    const [updateDocument] = useUpdateDocumentsMutation();
    useEffect(() => {
        setContact(document);
    }, [document]);
    const handelUpdate = () => {
        updateDocument({
            name: 'terms_and_conditions',
            body: {
                document: {
                    content: contact,
                },
            },
        });
    };
    return (
        <VStack p={6} alignItems="start" bgColor="rgba(255, 255, 255, 1)" borderRadius="8px" width="100%">
            <Textarea
                onChange={(e) => setContact(e.target.value)}
                rows={8}
                width={{ base: '100%', md: '90%' }}
                defaultValue={document?.content}
            />
            <Button mt={2} variant="solid" width={{ base: '80%', md: '20%' }} onClick={() => handelUpdate()}>
                Save
            </Button>
        </VStack>
    );
};

export default TermaAndConditionsTmplate;
