import React from 'react';
import { VStack } from '@chakra-ui/react';
import UpdateForm from 'components/organisms/coupons/forms/update-form';
import StoreData from 'components/organisms/coupons/components/store-data';

const UpdateCouponTemplate = ({ data, storesList }) => {
    return (
        <VStack w={{ base: '100%', md: '90%' }} gap={6} mb={6}>
            <UpdateForm data={data} />
            <StoreData storesList={storesList} data={data} />
        </VStack>
    );
};

export default UpdateCouponTemplate;
