import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-headers';

const countriesApi = createApi({
    reducerPath: 'countries',
    baseQuery: baseQuery,
    endpoints(builder) {
        return {
            removeCountry: builder.mutation({
                query: (id) => {
                    return {
                        url: `/lookups/countries/${id}`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: ['Country'],
            }),
            addCountry: builder.mutation({
                query: (body) => {
                    return {
                        url: '/lookups/countries',
                        method: 'POST',
                        body: body,
                    };
                },
                invalidatesTags: ['Country'],
            }),
            updateCountry: builder.mutation({
                query: (data) => {
                    return {
                        url: `/lookups/countries/${data?.id}`,
                        method: 'PUT',
                        body: data?.body,
                    };
                },
                invalidatesTags: ['Country'],
            }),
            fetchCountries: builder.query({
                query: (filterObj) => {
                    return {
                        url: '/lookups/countries',
                        method: 'GET',
                        params: filterObj,
                    };
                },
                providesTags: ['Country'],
            }),
            fetchCountry: builder.query({
                query: (id) => `/lookups/countries/${id}`,
                providesTags: (result, error, id) => [{ type: 'Country', id }],
            }),
        };
    },
});

export const {
    useFetchCountriesQuery,
    useAddCountryMutation,
    useUpdateCountryMutation,
    useFetchCountryQuery,
    useRemoveCountryMutation,
} = countriesApi;
export { countriesApi };
