import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-headers';

const bannersApi = createApi({
    reducerPath: 'banners',
    baseQuery: baseQuery,
    endpoints(builder) {
        return {
            removeBanner: builder.mutation({
                query: (id) => {
                    return {
                        url: `/lookups/featured_banners/${id}`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: ['Banner'],
            }),
            addBanner: builder.mutation({
                query: (body) => {
                    return {
                        url: '/lookups/featured_banners',
                        method: 'POST',
                        body: body,
                    };
                },
                invalidatesTags: ['Banner'],
            }),
            updateBanner: builder.mutation({
                query: (data) => {
                    return {
                        url: `/lookups/featured_banners/${data?.id}`,
                        method: 'PUT',
                        body: data?.body,
                    };
                },
                invalidatesTags: ['Banner'],
            }),
            fetchBanners: builder.query({
                query: (filterObj) => {
                    return {
                        url: '/lookups/featured_banners',
                        method: 'GET',
                        params: filterObj,
                    };
                },
                providesTags: ['Banner'],
            }),
            fetchBanner: builder.query({
                query: (id) => `/lookups/featured_banners/${id}`,
                providesTags: (result, error, id) => [{ type: 'Banner', id }],
            }),
        };
    },
});

export const {
    useFetchBannersQuery,
    useAddBannerMutation,
    useUpdateBannerMutation,
    useFetchBannerQuery,
    useRemoveBannerMutation,
} = bannersApi;
export { bannersApi };
