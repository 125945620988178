import { Box, Checkbox, HStack } from '@chakra-ui/react';
import React from 'react';
import CFormErrors from './CFormErrors';
import CFormLabels from './CFormLabels';

const CCheckbox = ({ label, name, formik, changeAction }) => {
    const errorsMsg = formik.errors[name];
    const value = formik.values[name];
    return (
        <HStack mb="5">
            <Checkbox
                defaultChecked
                lineHeight="1.25"
                alignItems="flex-start"
                fontWeight="normal"
                size="md"
                name={name}
                value={value}
                onBlur={formik.handleBlur}
                onChange={(e) => changeAction(e.target.checked)}
            />
            <CFormLabels label={label} />

            <CFormErrors errorsMsg={errorsMsg} />
        </HStack>
    );
};

export default CCheckbox;
