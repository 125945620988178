import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-headers';

const authApi = createApi({
    reducerPath: 'auth',
    baseQuery: baseQuery,
    endpoints(builder) {
        return {
            logout: builder.mutation({
                query: (id) => {
                    return {
                        url: `/auth/logout`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: ['Auth'],
            }),
            login: builder.mutation({
                query: (body) => {
                    return {
                        url: '/auth/admin_user/login',
                        method: 'POST',
                        body: body,
                    };
                },
                invalidatesTags: ['Auth'],
            }),
        };
    },
});

export const { useLoginMutation, useLogoutMutation } = authApi;
export { authApi };
