import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-headers';

const notificationsApi = createApi({
    reducerPath: 'notifications',
    baseQuery: baseQuery,
    endpoints(builder) {
        return {
            removeNotification: builder.mutation({
                query: (id) => {
                    return {
                        url: `/products/${id}`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: ['Notification'],
            }),
            addNotification: builder.mutation({
                query: (body) => {
                    return {
                        url: '/notifications',
                        method: 'POST',
                        body: body,
                    };
                },
                invalidatesTags: ['Notification'],
            }),
            updateNotification: builder.mutation({
                query: (data) => {
                    return {
                        url: `/notifications/${data?.id}`,
                        method: 'PUT',
                        body: data?.body,
                    };
                },
                invalidatesTags: ['Notification'],
            }),
            fetchNotifications: builder.query({
                query: (filterObj) => {
                    return {
                        url: '/notifications',
                        method: 'GET',
                        params: filterObj,
                    };
                },
                providesTags: ['Notification'],
            }),
            fetchNotification: builder.query({
                query: (id) => `/notifications/${id}`,
                providesTags: (result, error, id) => [{ type: 'Notification', id }],
            }),
        };
    },
});

export const {
    useFetchNotificationsQuery,
    useAddNotificationMutation,
    useUpdateNotificationMutation,
    useFetchNotificationQuery,
    useRemoveNotificationMutation,
} = notificationsApi;
export { notificationsApi };
