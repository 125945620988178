import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFetchUserQuery } from 'services/apis/users';
import ProfileTemplate from 'templates/profile';

const Profile = ({ setTitle, user }) => {
    useEffect(() => {
        setTitle('Profile');
    }, []);
    return <ProfileTemplate user={user} />;
};

export default Profile;
