import { Stack, Input } from '@chakra-ui/react';
import React from 'react';
import CFormLabels from './CFormLabels';
import CFormErrors from './CFormErrors';

const CInput = ({ label, placeholder, type = 'text', name, formik, ...rest }) => {
    const value = formik?.values?.[name];
    const errorsMsg = formik?.errors[name];

    return (
        <Stack width="100%">
            <CFormLabels label={label} />
            <Input
                {...rest}
                width="100%"
                size="md"
                id={name}
                variant="outlined"
                placeholder={placeholder}
                label={label}
                type={type}
                onChange={formik?.handleChange}
                value={value}
                onBlur={formik?.handleBlur}
                name={name}
            />
            <CFormErrors errorsMsg={errorsMsg} />
        </Stack>
    );
};

export default CInput;
