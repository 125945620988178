import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { productsApi } from './apis/productsApi';
import { categoriesApi } from './apis/categoryApi';
import { storesApi } from './apis/storesApi';
import modelSlice from './apis/models';
import { couponsApi } from './apis/couponsApi';
import { uploadApi } from './apis/upload';
import { profileApi } from './apis/profileApi';
import { notificationsApi } from './apis/notificationsApi';
import { authApi } from './apis/authApi';
import { countriesApi } from './apis/countriesApi';
import { currenciesApi } from './apis/currenciesApi';
import { usersApi } from './apis/users';
import { bannersApi } from './apis/banners';
import { documentsApi } from './apis/documents';

export const store = configureStore({
    reducer: {
        [storesApi.reducerPath]: storesApi.reducer,
        [productsApi.reducerPath]: productsApi.reducer,
        [categoriesApi.reducerPath]: categoriesApi.reducer,
        [couponsApi.reducerPath]: couponsApi.reducer,
        [uploadApi.reducerPath]: uploadApi.reducer,
        [profileApi.reducerPath]: profileApi.reducer,
        [notificationsApi.reducerPath]: notificationsApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [countriesApi.reducerPath]: countriesApi.reducer,
        [currenciesApi.reducerPath]: currenciesApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [bannersApi.reducerPath]: bannersApi.reducer,
        [documentsApi.reducerPath]: documentsApi.reducer,

        models: modelSlice.reducer,
    },

    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat(storesApi.middleware)
            .concat(productsApi.middleware)
            .concat(categoriesApi.middleware)
            .concat(couponsApi.middleware)
            .concat(uploadApi.middleware)
            .concat(profileApi.middleware)
            .concat(notificationsApi.middleware)
            .concat(authApi.middleware)
            .concat(countriesApi.middleware)
            .concat(currenciesApi.middleware)
            .concat(usersApi.middleware)
            .concat(bannersApi.middleware)
            .concat(documentsApi.middleware);
    },
});

setupListeners(store.dispatch);
