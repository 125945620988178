import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-headers';
import { pseudoPropNames } from '@chakra-ui/react';

const documentsApi = createApi({
    reducerPath: 'documents',
    baseQuery: baseQuery,
    endpoints(builder) {
        return {
            removeFaq: builder.mutation({
                query: (id) => {
                    return {
                        url: `/lookups/faqs/${id}`,
                        method: 'DELETE',
                    };
                },
                invalidatesTags: ['Document'],
            }),
            addFaq: builder.mutation({
                query: (body) => {
                    return {
                        url: '/lookups/faqs',
                        method: 'POST',
                        body: body,
                    };
                },
                invalidatesTags: ['Document'],
            }),
            updateContacts: builder.mutation({
                query: (data) => {
                    return {
                        url: `/lookups/contacts/${data?.id}`,
                        method: 'PUT',
                        body: data?.body,
                    };
                },
                invalidatesTags: ['Document'],
            }),
            updateDocuments: builder.mutation({
                query: (data) => {
                    return {
                        url: `/lookups/documents/${data?.name}`,
                        method: 'PUT',
                        body: data?.body,
                    };
                },
                invalidatesTags: ['Document'],
            }),
            updateFaq: builder.mutation({
                query: (data) => {
                    return {
                        url: `/lookups/faqs/${data?.id}`,
                        method: 'PUT',
                        body: data?.body,
                    };
                },
                invalidatesTags: ['Document'],
            }),
            fetchContacts: builder.query({
                query: (filter) => {
                    return {
                        url: '/lookups/contacts',
                        method: 'GET',
                        params: { page_size: '1000', ...filter },
                    };
                },
                providesTags: ['Document'],
            }),
            fetchFaq: builder.query({
                query: () => {
                    return {
                        url: '/lookups/faqs',
                        method: 'GET',
                        params: { page_size: '1000' },
                    };
                },
                providesTags: ['Document'],
            }),
            fetchDocuments: builder.query({
                query: (name) => {
                    return {
                        url: `/lookups/documents/${name}`,
                        method: 'GET',
                    };
                },
                providesTags: ['Document'],
            }),
        };
    },
});

export const {
    useRemoveFaqMutation,
    useUpdateFaqMutation,
    useFetchFaqQuery,
    useFetchContactsQuery,
    useFetchDocumentsQuery,
    useUpdateContactsMutation,
    useUpdateDocumentsMutation,
    useAddFaqMutation,
} = documentsApi;
export { documentsApi };
