import * as Yup from 'yup';
export const initialValues = (data) => ({
    ...data,
});
export const structure = (privilegeList, type, data) => {
    return [
        {
            name: `avatar`,
            kind: 'upload',
            type: 'text',
            label: data?.avatar ? 'Edit photo' : 'Add photo',
            width: '50%',
            type: type?.type,
            value: data?.avatar,
        },
        {
            name: `name`,
            kind: 'input',
            type: 'text',
            label: 'Admin name',
            width: '50%',
        },
        {
            name: `email`,
            kind: 'input',
            type: 'email',
            label: 'Admin email',
            width: '50%',
        },
        {
            name: `phone_number`,
            kind: 'phone',
            type: 'text',
            label: 'Admin phone number',
            width: '50%',
        },

        {
            name: `privilege`,
            kind: 'select',
            label: 'Type',
            width: '50%',
            isMulti: false,
            options: privilegeList,
            placeholder: data?.name,
            // defaultValue: data,
        },

        {
            name: `password`,
            kind: 'input',
            type: 'text',
            label: 'Password',
            width: '50%',
        },
        {
            name: `password_confirmation`,
            kind: 'input',
            type: 'text',
            label: 'Confirm password',
            width: '50%',
        },
    ];
};

export const validationSchema = () =>
    Yup.object({
        name: Yup.string().required('required'),
        email: Yup.string().email().required('required'),
        password: Yup.string().required('Password is required'),
        password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });
