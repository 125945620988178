import React, { useEffect } from 'react';
import { useFetchFaqQuery } from 'services/apis/documents';
import FaqTemplate from 'templates/documents/faq';

const Faq = ({ setTitle }) => {
    const { data, isFetching } = useFetchFaqQuery();
    const list = data?.data?.faqs;
    useEffect(() => {
        setTitle('FAQs');
    }, []);
    return <FaqTemplate list={list} isFetching={isFetching} />;
};

export default Faq;
