import { Box, Button, Checkbox, Flex, HStack, Stack } from '@chakra-ui/react';
import CEditableInput from 'common/forms/components/CEditableInput';
import CFormLabels from 'common/forms/components/CFormLabels';
import CUpload from 'common/forms/components/CUpload';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openChangePasswordModel, openDeactivateProfile } from 'services/apis/models';
import { useUpdateProductMutation } from 'services/apis/productsApi';
import { useUpdateAdminUserMutation } from 'services/apis/users';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
const Form = ({ data }) => {
    const dispatch = useDispatch();
    const [updateAdminUser] = useUpdateAdminUserMutation();
    const [newLogo, setNewLogo] = useState(null);
    const [phone, setPhone] = useState(null);

    //console.log(data);
    const handelUpdate = (val, name) => {
        const body = {
            admin_user: { ...data, [`${name}`]: val },
        };

        updateAdminUser({ id: data?.id, body });
    };
    useEffect(() => {
        if (newLogo) handelUpdate(newLogo, 'avatar');
    }, [newLogo]);

    const handelPhone = (value, country, e, formattedValue) => {
        const countryCode = country?.dialCode?.length;
        const phone_number = value?.slice(countryCode - 1);
        const body = {
            admin_user: { ...data, phone_number: phone_number, country_code: `+${country?.dialCode}` },
        };
        setPhone(value);
        updateAdminUser({ id: data?.id, body }).then((res) => {
            if (res?.error) toast.error(res?.error?.data?.message);
        });
    };
    useEffect(() => {
        setPhone(`${data?.country_code?.slice(0)}${data?.phone_number}`);
    }, [data]);
    return (
        <Flex
            gap={6}
            width="100%"
            flexWrap="wrap"
            flexDirection="column"
            bg="white"
            p="20px"
            borderRadius="10px"
            mt={6}
        >
            <Stack flexDirection={{ base: 'column', md: 'row' }} justifyContent="space-between">
                <Box width={{ base: '100%', md: '50%' }}>
                    <CUpload
                        changeAction={(value) => setNewLogo(value)}
                        label="Edit photo"
                        value={data?.avatar}
                        type="edit"
                    />
                </Box>

                <Stack width={{ base: '100%', md: '50%' }} flexDirection={{ base: 'column', md: 'row' }}>
                    <Button
                        variant="solid"
                        width={{ base: '100%', md: '45%' }}
                        onClick={() => dispatch(openDeactivateProfile())}
                    >
                        Deactivate
                    </Button>
                    <Button
                        variant="outline"
                        width={{ base: '100%', md: 'auto' }}
                        onClick={() => dispatch(openChangePasswordModel())}
                    >
                        Change Password
                    </Button>
                </Stack>
            </Stack>
            <CEditableInput defaultValue={data?.name} name="name" handelSubmit={handelUpdate} label="Name" />
            <CEditableInput defaultValue={data?.email} name="email" handelSubmit={handelUpdate} label="Email" />
            <Stack width="50%">
                <CFormLabels label="Phone number" />
                <PhoneInput
                    country={'us'}
                    value={phone}
                    onChange={(value, country, e, formattedValue) => handelPhone(value, country, e, formattedValue)}
                />
            </Stack>
        </Flex>
    );
};

export default Form;
