import React from 'react';
import FormIndex from 'common/forms';
import { useFormik } from 'formik';
import { structure } from 'components/organisms/stores/forms/add-form';
import { Button } from '@chakra-ui/react';
import { useAddStoreMutation } from 'services/apis/storesApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const CreateStoreTemplate = ({ categoriesList }) => {
    const [addStore, { isLoading }] = useAddStoreMutation();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: { is_visible: true },
        onSubmit: (values) => {
            const categories = values?.category_ids?.map((category) => {
                return category?.value;
            });
            addStore({ store: { ...values, category_ids: categories } }).then((res) => {
                if (res?.error) toast.error(res?.error?.data?.message);
                else navigate('/stores');
            });
        },
    });
    return (
        <FormIndex formStructures={structure(categoriesList)} formik={formik}>
            <Button
                isLoading={isLoading}
                type="submit"
                variant="solid"
                width={{ base: '100%', md: '25%' }}
                alignSelf="self-start"
            >
                Save
            </Button>
        </FormIndex>
    );
};

export default CreateStoreTemplate;
