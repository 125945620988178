import { HStack, Stack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import { useFetchCtegoriesQuery } from 'services/apis/categoryApi';
import CreateStoreTemplate from 'templates/stores/create';

const CreateStore = ({ setTitle }) => {
    const { data: categories } = useFetchCtegoriesQuery({ page_size: 200 });
    const categoriesList = categories?.data?.categories;
    useEffect(() => {
        const title = (
            <HStack>
                <NavLink to="/stores">Stores</NavLink>
                <IoIosArrowForward />
                <Text>Add new Store</Text>
            </HStack>
        );
        setTitle(title);
    }, []);
    return <CreateStoreTemplate categoriesList={categoriesList} />;
};

export default CreateStore;
