import { HStack, Stack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import { useFetchCtegoriesQuery } from 'services/apis/categoryApi';
import { useFetchCurrenciesQuery } from 'services/apis/currenciesApi';
import { useFetchStoresQuery } from 'services/apis/storesApi';
import CreateProductTemplate from 'templates/products/create';

const CreateProduct = ({ setTitle }) => {
    const { data: categories } = useFetchCtegoriesQuery({ page_size: 200 });
    const categoriesList = categories?.data?.categories;
    const { data: stores } = useFetchStoresQuery({});
    const storesList = stores?.data?.stores;
    const { data: currencies, error, isLoading } = useFetchCurrenciesQuery({ page_size: 100 });
    const currenciesList = currencies?.data?.currencies;
    useEffect(() => {
        const title = (
            <HStack>
                <NavLink to="/products">Products</NavLink>
                <IoIosArrowForward />
                <Text>Add new product</Text>
            </HStack>
        );
        setTitle(title);
    }, []);
    return (
        <CreateProductTemplate
            categoriesList={categoriesList}
            storesList={storesList}
            currenciesList={currenciesList}
        />
    );
};

export default CreateProduct;
