import { Flex } from '@chakra-ui/react';
import CEditableInput from 'common/forms/components/CEditableInput';
import CUpload from 'common/forms/components/CUpload';
import React, { useEffect, useState } from 'react';
import { useUpdateCategoryMutation } from 'services/apis/categoryApi';

const UpdateForm = ({ data }) => {
    const [updateCategory] = useUpdateCategoryMutation();
    const [newLogo, setNewLogo] = useState(null);

    const handelUpdate = (val, name) => {
        const body = {
            category: { ...data, [`${name}`]: val },
        };
        updateCategory({ id: data?.id, body });
    };
    useEffect(() => {
        if (newLogo) handelUpdate(newLogo, 'logo');
    }, [newLogo]);
    return (
        <Flex
            gap={6}
            width="100%"
            flexWrap="wrap"
            flexDirection="column"
            bg="white"
            p="20px"
            borderRadius="10px"
            mt={6}
        >
            <CUpload changeAction={(value) => setNewLogo(value)} label="Edit photo" value={data?.logo} type="edit" />

            <CEditableInput
                defaultValue={data?.name_en}
                name="name_en"
                handelSubmit={handelUpdate}
                label="Category name in English"
            />
            <CEditableInput
                defaultValue={data?.name_ar}
                name="name_ar"
                handelSubmit={handelUpdate}
                label="Category name in Arabic"
            />
        </Flex>
    );
};

export default UpdateForm;
