import * as Yup from 'yup';
export const initialValues = (data) => ({
    ...data,
});
export const structure = (categories) => [
    {
        name: `name`,
        kind: 'input',
        type: 'text',
        label: 'Coupon name ',
        width: '50%',
    },
    {
        name: `store_id`,
        kind: 'select',
        type: 'text',
        label: 'Store',
        width: '50%',
        isMulti: false,
        options: categories,
    },
    {
        name: `for`,
        kind: 'select',
        type: 'text',
        label: 'Coupon For',
        width: '50%',
        isMulti: false,
        options: [
            { name: 'all', id: 0 },
            { name: 'web', id: 1 },
            { name: 'mobile', id: 2 },
        ],
    },
    {
        name: `description_en`,
        kind: 'input',
        type: 'text',
        label: 'Description in English',
        width: '50%',
    },
    {
        name: `description_ar`,
        kind: 'input',
        type: 'text',
        label: 'Description in Arabic',
        width: '50%',
    },
    {
        name: `is_visible`,
        kind: 'checkbox',
        type: 'text',
        label: 'In Home',
        width: '50%',
    },
    {
        name: `is_top_deal`,
        kind: 'checkbox',
        type: 'text',
        label: 'Is Top Store',
        width: '50%',
    },
];
export const validationSchema = () =>
    Yup.object({
        // name_en: Yup.string().required('required'),
        // name_ar: Yup.string().required('required'),
        is_visible: Yup.boolean().default(false),
    });
