import React from 'react';
import CFormErrors from './CFormErrors';
import Select from 'react-select';
import { Flex } from '@chakra-ui/react';
import CFormLabels from './CFormLabels';

const SelectOptions = ({
    defaultValue,
    options,
    placeholder,
    changeAction,
    label,
    formik,
    name,
    index,

    ...rest
}) => {
    const errorsMsg = formik?.errors[name];

    const value = formik ? formik?.values[name] : defaultValue;
    const handelOptions = options?.map((val) => {
        return {
            label: val?.name,
            value: val?.id,
        };
    });

    const indexs = {
        0: 10,
        1: 9,
        2: 8,
        3: 7,
        4: 6,
        5: 5,
        6: 4,
        7: 3,
        8: 2,
        9: 1,
        10: 0,
    };

    return (
        <Flex
            w={'100%'}
            justifyContent="center"
            flexDirection={'column'}
            position="relative"
            zIndex={`${1 + indexs[index]} !important`}
            loadingMessage={() => <p>loading...</p>}
            onKeyDown={(e) => e.stopPropagation()}
            gap={2}
        >
            <CFormLabels label={label} />

            <Select
                id={name}
                name={name}
                options={handelOptions}
                onChange={(value) => changeAction(value)}
                value={value}
                placeholder={placeholder}
                className="search-select"
                defaultValue={defaultValue}
                {...rest}
            />
            <CFormErrors errorsMsg={errorsMsg} />
        </Flex>
    );
};

export default SelectOptions;
