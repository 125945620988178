import {
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuList,
    Stack,
    Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { LuFilter } from 'react-icons/lu';
import { MdAdd } from 'react-icons/md';

const Header = ({
    Data,
    title,
    isFilter,
    isSearch,
    searchFun,
    filterList,
    createBtnTitle,
    createBtnFun,
    placeholder,
}) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        const timeId = setTimeout(() => {
            searchFun(value);
        }, 500);
        return () => clearTimeout(timeId);
    }, [value]);

    return (
        <Stack
            direction="row"
            alignSelf={title ? 'center' : 'flex-end'}
            justifyContent="space-between"
            p={2}
            width={title ? '100%' : 'fit-content'}
        >
            {title && (
                <Text fontSize="22px" fontWeight="500">
                    {title}
                </Text>
            )}

            <Flex gap={2}>
                {isSearch && (
                    <Stack flexDirection="row" background="#80808012" borderRadius="10px">
                        <InputGroup>
                            <InputLeftElement pointerEvents="none">
                                <FaSearch color="gray.100" />
                            </InputLeftElement>
                            <Input
                                bg="white"
                                placeholder={placeholder}
                                onChange={(e) => {
                                    setValue(e.target.value);
                                }}
                            />
                        </InputGroup>
                    </Stack>
                )}

                {isFilter && (
                    <Menu closeOnSelect={false}>
                        <MenuButton
                            fontWeight="400"
                            fontSize="16px"
                            as={Button}
                            leftIcon={<LuFilter />}
                            colorScheme="main_color"
                            variant="outline"
                        >
                            Filter
                        </MenuButton>
                        <MenuList minWidth="240px">{filterList}</MenuList>
                    </Menu>
                )}

                {createBtnFun && (
                    <Button onClick={createBtnFun} bg="main_color" color="white" fontSize="15px">
                        <MdAdd color="white" size="24px" /> {createBtnTitle}
                    </Button>
                )}
            </Flex>
        </Stack>
    );
};

export default Header;
