import { Box, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Switch } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineExport } from 'react-icons/ai';
import { BsThreeDots } from 'react-icons/bs';
import { LiaEditSolid } from 'react-icons/lia';
import { RiDeleteBin6Fill } from 'react-icons/ri';

const Actions = ({ list, type = 'menu', item }) => {
    const actionsIcons = {
        details: <AiOutlineExport style={{ color: '#8A8A8A', width: '16px', cursor: 'pointer' }} />,
        active: <Switch size="sm" colorScheme="green" defaultChecked={item?.is_visible} />,
        edit: <LiaEditSolid style={{ color: '#8A8A8A', width: '16px', cursor: 'pointer' }} />,
        delete: <RiDeleteBin6Fill style={{ color: '#EB0000', cursor: 'pointer' }} />,
    };
    return type == 'menu' ? (
        <Menu closeOnSelect={false}>
            <MenuButton as={IconButton} aria-label="Options" icon={<BsThreeDots color="black" />} />
            <MenuList sx={{ display: 'flex' }} minWidth="140px" bg="#F2F2F2">
                {list?.map((data, index) => {
                    return (
                        <MenuItem bg="#F2F2F2" key={index} onClick={() => data?.func()} sx={{ width: 'fit-content' }}>
                            {actionsIcons[data.type]}
                        </MenuItem>
                    );
                })}
            </MenuList>
        </Menu>
    ) : (
        <HStack gap={3}>
            {list?.map((data, index) => {
                return (
                    <Box bg="#F2F2F2" key={index} onClick={() => data?.func()} sx={{ width: 'fit-content' }}>
                        {actionsIcons[data.type]}
                    </Box>
                );
            })}
        </HStack>
    );
};

export default Actions;
