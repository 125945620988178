import React from 'react';
import FormIndex from 'common/forms';
import { useFormik } from 'formik';
import { structure } from 'components/organisms/stores/forms/add-form';
import { Button, VStack } from '@chakra-ui/react';
import { useAddStoreMutation } from 'services/apis/storesApi';
import { useNavigate } from 'react-router-dom';
import Form from 'components/organisms/profile/form';
import UserType from 'components/organisms/profile/userType';
import DeactivateProfile from 'components/organisms/profile/models/deactivate';
import ChangePassword from 'components/organisms/profile/models/change-password';
const ProfileTemplate = ({ user }) => {
    return (
        <VStack w={{ base: '100%', md: '90%' }} gap={6} mb={6}>
            <Form data={user} />
            <UserType user={user} />
            <DeactivateProfile />
            <ChangePassword />
        </VStack>
    );
};

export default ProfileTemplate;
