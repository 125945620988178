import { Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFetchUsersQuery } from 'services/apis/users';
import UsersTemplate from 'templates/users';

const Users = ({ setTitle }) => {
    const [filterObject, setFilterObject] = useState({
        page_number: 1,
        keyword: '',
    });
    const { data, error, isLoading } = useFetchUsersQuery({ user_type: 'user', filterObject: filterObject });
    const list = data?.data?.users;
    const count = Math.ceil(data?.extra?.total_count / 10);

    useEffect(() => {
        setTitle('Users');
    }, []);

    return isLoading ? <Spinner /> : <UsersTemplate list={list} count={count} setFilterObject={setFilterObject} />;
};

export default Users;
