import { Divider, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';

const UserDetailsTemplate = ({ data }) => {
    const list = [
        { label: 'First name', value: data?.name },
        { label: 'Last name', value: data?.name },
        { label: 'Mobile number', value: data?.name },
        { label: 'Number of copied coupons', value: data?.name },
    ];
    return (
        <VStack w="95%" gap={6} mb={6}>
            <Flex gap={6} width="100%" flexWrap="wrap" flexDirection="column" bg="white" p="20px" borderRadius="10px">
                {list?.map((data, index) => {
                    return (
                        <>
                            <HStack key={index} gap={14}>
                                <Text fontSize="16px" color="rgba(129, 129, 129, 1)">
                                    {data?.label}
                                </Text>
                                <Text fontSize="16px">{data?.value}</Text>
                            </HStack>
                            {index != list?.length - 1 && <Divider width={'100%'} />}
                        </>
                    );
                })}
            </Flex>
        </VStack>
    );
};

export default UserDetailsTemplate;
