import * as Yup from 'yup';
export const initialValues = (data) => ({
    ...data,
});
export const structure = () => [
    {
        name: `iso_code`,
        kind: 'input',
        type: 'text',
        label: 'Code',
        width: '50%',
    },

    {
        name: `name_en`,
        kind: 'input',
        type: 'text',
        label: 'Currency name English',
        width: '50%',
    },
    {
        name: `name_ar`,
        kind: 'input',
        type: 'text',
        label: 'Currency name Arabic',
        width: '50%',
    },
];
export const validationSchema = () =>
    Yup.object({
        // name_en: Yup.string().required('required'),
        // name_ar: Yup.string().required('required'),
    });
