import React from 'react';
import FormIndex from 'common/forms';
import { useFormik } from 'formik';
import { Button } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { structure, validationSchema } from 'components/organisms/users/forms/form';
import { useAddAdminUserMutation, useUpdateAdminUserMutation } from 'services/apis/users';
const UserFormTemplate = ({ data, user }) => {
    const { id, user_type } = useParams();

    const [addAdminUser, { isLoading }] = useAddAdminUserMutation();
    const [updateAdminUser] = useUpdateAdminUserMutation();

    const navigate = useNavigate();
    const privilegeList = [
        { name: 'admin', id: 0 },
        { name: 'super_admin', id: 1 },
        { name: 'editor', id: 2 },
    ];
    const adminPrivilageslist = [{ name: 'editor', id: 2 }];
    const currentPriv = privilegeList?.find((val) => data?.privilege == val?.name);
    const initialValues = {
        ...data,
        privilege: null,
        phone_number: `${data?.country_code?.slice(0)}${data?.phone_number}`,
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: id ? initialValues : {},
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const countryCode = values?.country_code?.length;
            const phone_number = values?.phone_number?.slice(countryCode - 1);
            if (!id) {
                addAdminUser({ admin_user: { ...values, privilege: values?.privilege?.value, phone_number } }).then(
                    (res) => {
                        if (res?.error) toast.error(res?.error?.data?.message);
                        else navigate(-1);
                    },
                );
            } else if (id) {
                updateAdminUser({
                    id: data?.id,
                    body: {
                        admin_user: {
                            ...values,
                            privilege: user?.privilege == 'admin' ? 'editor' : values?.privilege?.value,
                            phone_number,
                        },
                    },
                }).then((res) => {
                    if (res?.error) toast.error(res?.error?.data?.message);
                    else navigate(-1);
                });
            }
        },
    });

    return (
        <FormIndex
            formStructures={structure(
                user?.privilege == 'admin' ? adminPrivilageslist : privilegeList,
                { type: id ? 'edit' : 'add' },
                currentPriv,
            )}
            formik={formik}
        >
            <Button
                isLoading={isLoading}
                type="submit"
                variant="solid"
                width={{ base: '100%', md: '25%' }}
                alignSelf="self-start"
            >
                Save
            </Button>
        </FormIndex>
    );
};

export default UserFormTemplate;
