import React, { useEffect } from 'react';
import { useFetchDocumentsQuery } from 'services/apis/documents';
import PrivactpolicyTmplate from 'templates/documents/privactpolicy';

const PrivacyPolicy = ({ setTitle }) => {
    const { data } = useFetchDocumentsQuery('privacy_policy');
    const document = data?.data?.document;
    useEffect(() => {
        setTitle('Privacy Policy');
    }, []);
    return <PrivactpolicyTmplate document={document} />;
};

export default PrivacyPolicy;
