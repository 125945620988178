import { Checkbox, Flex, HStack } from '@chakra-ui/react';
import CEditableInput from 'common/forms/components/CEditableInput';
import CFormLabels from 'common/forms/components/CFormLabels';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { useUpdateStoreMutation } from 'services/apis/storesApi';
import CUpload from 'common/forms/components/CUpload';

const UpdateForm = ({ data, categoriesList }) => {
    const [updateStore, updateStorResults] = useUpdateStoreMutation();
    const [newLogo, setNewLogo] = useState(null);
    const handelUpdate = (val, name) => {
        const body = {
            store: { ...data, [`${name}`]: val },
        };

        updateStore({ id: data?.id, body });
    };

    useEffect(() => {
        if (newLogo) handelUpdate(newLogo, 'logo');
    }, [newLogo]);

    const categoriesOptions = categoriesList?.map((data) => {
        return { label: data?.name, value: data?.id };
    });
    return (
        <Flex
            gap={6}
            width="100%"
            flexWrap="wrap"
            flexDirection="column"
            bg="white"
            p="20px"
            borderRadius="10px"
            mt={6}
        >
            <CUpload
                changeAction={(value) => setNewLogo(value)}
                label="Edit photo h=150px,w=230px"
                value={data?.logo}
                type="edit"
            />
            <CEditableInput
                defaultValue={data?.name_en}
                name="name_en"
                handelSubmit={handelUpdate}
                label="Store name English"
            />
            <CEditableInput
                defaultValue={data?.name_ar}
                name="name_ar"
                handelSubmit={handelUpdate}
                label="Store name Arabic"
            />
            <CEditableInput defaultValue={data?.link} name="link" handelSubmit={handelUpdate} label="Store link" />
            <Flex
                w={{ base: '100%', md: '50%' }}
                justifyContent="center"
                flexDirection={'column'}
                position="relative"
                loadingMessage={() => <p>loading...</p>}
                onKeyDown={(e) => e.stopPropagation()}
                gap={2}
            >
                <CFormLabels label={'Categories'} />

                <Select
                    options={categoriesOptions}
                    onChange={(value) =>
                        handelUpdate(
                            value?.map((val) => val?.value),
                            'category_ids',
                        )
                    }
                    className="search-select"
                    isMulti={true}
                    defaultValue={data?.categories}
                />
            </Flex>
        </Flex>
    );
};

export default UpdateForm;
