import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';
import FormIndex from 'common/forms';
import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openCreateCoupons } from 'services/apis/models';
import { structure } from '../forms/add-coupon';
import { useAddCouponMutation } from 'services/apis/couponsApi';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddCoupons = () => {
    const [addCoupon, addCategoryResults] = useAddCouponMutation();
    const { id } = useParams();

    const formik = useFormik({
        initialValues: {},
        onSubmit: (values) => {
            addCoupon({ promocode: { ...values, store_id: id, is_visible: true } }).then((res) => {
                if (res?.error) toast.error(res?.error?.data?.message);
                else dispatch(openCreateCoupons());
            });
        },
    });

    const dispatch = useDispatch();

    const open = useSelector((state) => state.models.createCoupons);

    const handelClose = () => {
        dispatch(openCreateCoupons());
    };
    return (
        <Modal isOpen={open} onClose={handelClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody>
                    <Text px="20px" fontSize="20px" fontWeight="600" mb="-20px" pt="20px">
                        Add a new coupon
                    </Text>
                    <FormIndex formStructures={structure()} formik={formik} width="100%">
                        <Flex
                            flexDir={{ base: 'column', md: 'row' }}
                            width="100%"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Button
                                colorScheme="blue"
                                mr={3}
                                onClick={handelClose}
                                variant="outline"
                                width={{ base: '100%', md: '25%' }}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant="solid" width={{ base: '100%', md: '25%' }}>
                                Save
                            </Button>
                        </Flex>
                    </FormIndex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AddCoupons;
