import { Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFetchNotificationsQuery } from 'services/apis/notificationsApi';
import NotificationsTemplate from 'templates/notifications';

const Notifications = ({ setTitle }) => {
    const [filterObject, setFilterObject] = useState({
        page_number: 1,
        keyword: '',
    });
    const { data, error, isLoading } = useFetchNotificationsQuery(filterObject);
    const list = data?.data?.notifications;
    const count = Math.ceil(data?.extra?.total_count / 10);
    useEffect(() => {
        setTitle('Notifications');
    }, []);

    return isLoading ? (
        <Spinner />
    ) : (
        <NotificationsTemplate count={count} list={list} setFilterObject={setFilterObject} />
    );
};

export default Notifications;
