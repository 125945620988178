import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const uploadApi = createApi({
    reducerPath: 'Photos',

    baseQuery: fetchBaseQuery({ baseUrl: 'https://api.xdeal.net/api/v1' }),
    endpoints(builder) {
        return {
            uploadS3: builder.mutation({
                query: (body) => {
                    return {
                        url: '/uploads',
                        method: 'POST',
                        body: body,
                    };
                },
                invalidatesTags: ['Photo'],
            }),
            uploadPhoto: builder.mutation({
                query: (data) => {
                    //console.log(data);
                    return {
                        url: data?.url,
                        method: 'PUT',
                        body: data?.body,
                    };
                },
                invalidatesTags: ['Photo'],
            }),
        };
    },
});

export const { useUploadS3Mutation, useUploadPhotoMutation } = uploadApi;
export { uploadApi };
