import { HStack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import UserDetailsTemplate from 'templates/users/details';

const UserDetails = ({ setTitle }) => {
    const data = { name: 'User' };
    useEffect(() => {
        const title = (
            <HStack>
                <NavLink to="/users">Users</NavLink>
                <IoIosArrowForward />
                <Text>{data?.name} </Text>
            </HStack>
        );
        setTitle(title);
    }, []);
    return <UserDetailsTemplate data={data} />;
};

export default UserDetails;
